import { css } from '@emotion/react'
import styled from '@emotion/styled'
import Facebook from '../sections/assets/Facebook.svg'
import Instagram from '../sections/assets/Instagram.svg'
import LinkedIn from '../sections/assets/LinkedIn.svg'
import Link from './Link'

const SocialLink = styled(Link, {
  shouldForwardProp: (prop) => prop !== 'size',
})<{ size: IconSize }>(
  ({ theme, size }) => css`
    width: ${size === 'small' ? '24px' : '40px'};
    height: ${size === 'small' ? '24px' : '40px'};

    svg {
      width: 100%;
      height: 100%;
      transition: fill 0.2s ease-in-out;
    }

    :hover {
      svg {
        fill: ${theme.colors.neutral3};
      }
    }
  `,
)

type Icon = 'instagram' | 'facebook' | 'linkedin'
type IconSize = 'small' | 'large'

interface Props {
  href: string
  target?: string
  icon: string
  size?: IconSize
}

const RenderSocialIcon = ({ icon }: { icon: Icon }) => {
  switch (icon) {
    case 'instagram':
      return <Instagram />

    case 'facebook':
      return <Facebook />

    case 'linkedin':
      return <LinkedIn />

    default:
      return null
  }
}

const SocialIcon = ({ href, target, icon, size = 'large' }: Props) => {
  return (
    <SocialLink href={href} target={target} aria-label={icon} size={size}>
      <RenderSocialIcon icon={icon as Icon} />
    </SocialLink>
  )
}

export default SocialIcon
