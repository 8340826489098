import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ElementType, forwardRef, InputHTMLAttributes } from 'react'

import CheckSvg from '../icons/Check.svg'

const Container = styled('label', {
  shouldForwardProp: (prop) => prop !== 'disabled' && prop !== 'as',
})<{ disabled?: boolean }>(({ disabled, theme }) => [
  css`
    display: inline-block;
    width: 24px;
    height: 24px;
    position: relative;
    color: ${theme.colors.neutral2};

    input[type='checkbox']:focus + * {
      outline: currentColor solid 1px;
      outline: -webkit-focus-ring-color solid 1px;
    }
    input[type='checkbox']:checked + * + * {
      transform: scale(1);
    }
  `,
  disabled &&
    css`
      filter: grayscale(100%);
    `,
])
const Background = styled.div(
  ({ theme }) => css`
    width: 100%;
    height: 100%;
    border: 1px solid currentColor;

    transition: box-shadow 100ms ease-in-out;
    label:hover & {
      border: 1px solid ${theme.colors.neutral3};
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
    }
  `,
)
const Input = styled.input`
  opacity: 0;
  position: absolute;
`
const Active = styled.div(
  ({ theme }) => css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: transform 200ms cubic-bezier(0.2, 0.9, 0.42, 1);
    transform: scale(0);
    background: ${theme.colors.primaryBlue};
    color: ${theme.colors.neutral0};
  `,
)
const StyledCheck = styled(CheckSvg)(
  ({ theme }) => css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: ${theme.spacing.x2}px;
    height: ${theme.spacing.x2}px;
  `,
)

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  as?: ElementType
}

// eslint-disable-next-line react/display-name
const Checkbox = forwardRef<HTMLInputElement, Props>(
  ({ disabled, value, className, as, ...others }: Props, ref) => (
    <Container className={className} disabled={disabled} as={as}>
      <Input
        type="checkbox"
        value={value}
        disabled={disabled}
        ref={ref}
        {...others}
      />
      <Background />
      <Active>
        <StyledCheck />
      </Active>
    </Container>
  ),
)

export default Checkbox
