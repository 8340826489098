import RichText from '../components/RichText'
import TextSection from '../sections/TextSection'
import { TextSectionFragment } from '../graphql/textSectionFragment'

interface Props {
  content: TextSectionFragment
}

const TextSectionBlock = ({ content: { text, anchor }, ...others }: Props) => {
  if (!text) {
    return null
  }

  return (
    <TextSection id={anchor || undefined} {...others}>
      <RichText text={text} />
    </TextSection>
  )
}

export default TextSectionBlock
