import { PHASE_PRODUCTION_SERVER } from 'next/constants'

import {
  // ArticleRecord,
  PageRecord,
  SiteLocale,
} from '../graphql/datoSchema.generated'
import { SiteConfig } from '../graphql/siteConfig'
import { getHomepageSlug } from './env'

// const newsFallbackSlug = 'news'
const brokenFallback = '#broken-link'

const resolveUrlForRecord = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  siteConfig: SiteConfig,
  record: Pick<PageRecord, '__typename' | 'slug'>,
  // | Pick<ArticleRecord, '__typename' | 'slug'>
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  locale?: SiteLocale,
) => {
  switch (record.__typename) {
    case 'PageRecord':
      if (!record.slug) {
        return brokenFallback
      }
      if (record.slug === getHomepageSlug()) {
        return '/'
      }
      return `/${record.slug}`
    // case 'ArticleRecord': {
    //   let newsSlug
    //   if (locale) {
    //     newsSlug =
    //       siteConfig.newsIndex?.allSlugs?.find((i) => i.locale === locale)
    //         ?.value || newsFallbackSlug
    //   } else {
    //     newsSlug = siteConfig.newsIndex?.slug || newsFallbackSlug
    //   }

    //   return record.slug ? `/${newsSlug}/${record.slug}` : brokenFallback
    // }
    default: {
      const message = `Record type not recognized: ${record.__typename}`
      if (process.env.NEXT_PHASE !== PHASE_PRODUCTION_SERVER) {
        throw new Error(message)
      } else {
        // eslint-disable-next-line no-console
        console.error(message)
        // TODO: Report to Application Insights
        return brokenFallback
      }
    }
  }
}

export default resolveUrlForRecord
