import { FunctionComponent } from 'react'

import TextSection from './TextSection'
import FooterSection from './FooterSection'
import NavigationCardsSection from './NavigationCardsSection'
import AboutSection from './AboutSection'
import CallToActionSection from './CallToActionSection'
import CollageHeader from './CollageHeader'
import CollageBanner from './CollageBanner'
import NavigationBarSection from './NavigationBarSection'
import ContactFormSection from './ContactFormSection'
import JobFeedSection from './JobFeedSection'
import ProcessWithStepsSection from './ProcessWithStepsSection'
import TestimonialsSection from './TestimonialsSection'
import FeaturesSection from './FeaturesSection'
import TextWithCallToActionSection from './TextWithCallToActionSection'
import PartnersSection from './PartnersSection'
import ContentGroupSection from './ContentGroupSection'
import JobListingSection from './JobListingSection'
import SimpleHeaderSection from './SimpleHeaderSection'
import PodcastListingSection from './PodcastListingSection'
import NewsListingSection from './NewsListingSection'
import EmployeeSection from './EmployeeSection'
import TextWithCollageSection from './TextWithCollageSection'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyComponent = FunctionComponent<any>

const blocks: Record<string, AnyComponent> = {
  TextSection,
  FooterSection,
  NavigationCardsSection,
  AboutSection,
  CallToActionSection,
  CollageHeader,
  CollageBanner,
  NavigationBarSection,
  ContactFormSection,
  JobFeedSection,
  ProcessWithStepsSection,
  TestimonialsSection,
  FeaturesSection,
  TextWithCallToActionSection,
  PartnersSection,
  ContentGroupSection,
  JobListingSection,
  SimpleHeaderSection,
  PodcastListingSection,
  NewsListingSection,
  EmployeeSection,
  TextWithCollageSection,
}

export default blocks

export interface BlockGetStaticPropsContext<Data> {
  block: Data
}
