import { css } from '@emotion/react'
import styled from '@emotion/styled'
import Email from '../icons/Email.svg'
import Location from '../icons/LocationBlue.svg'
import Phone from '../icons/Phone.svg'
import SocialIcon from './SocialIcon'
import Link from './Link'

const Contacts = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-flow: column;
    padding: ${theme.spacing.x4}px;
    background-color: ${theme.colors.white};
    z-index: ${theme.zIndex.relative5};
    position: relative;

    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      width: 300px;
    }
  `,
)

const ContactTitle = styled.h3(
  ({ theme }) => css`
    ${theme.text.heading3(theme)}
    color: ${theme.colors.primaryBlue};
    margin-bottom: ${theme.spacing.x4}px;
  `,
)

const Contact = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-flow: column;

    :not(:first-of-type) {
      margin-top: ${theme.spacing.x4}px;
    }
  `,
)

const ContactName = styled.p(
  ({ theme }) => css`
    color: ${theme.colors.primaryBlue};
    font-weight: bold;
    margin-top: 0;
    margin-bottom: ${theme.spacing.x1}px;
  `,
)

const ContactDetails = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-flow: column;
    color: ${theme.colors.bodyTextColor};
    gap: ${theme.spacing.x1}px;
  `,
)

const ContactInfo = styled(Link)(
  ({ theme }) => css`
    ${theme.links.neutral3(theme)}
    display: flex;
    flex-flow: row;
    gap: ${theme.spacing.x2}px;
  `,
)

const Address = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-flow: column;

    :not(:first-of-type) {
      margin-top: ${theme.spacing.x4}px;
    }
  `,
)

const AddressTitle = styled.p(
  ({ theme }) => css`
    color: ${theme.colors.primaryBlue};
    font-weight: bold;
    margin-top: 0;
    margin-bottom: ${theme.spacing.x1}px;
  `,
)

const AddressInfoLink = styled(Link)(
  ({ theme }) => css`
    ${theme.links.neutral3(theme)}
    display: flex;
    flex-flow: row;
    gap: ${theme.spacing.x2}px;
  `,
)

const AddressInfo = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-flow: row;
    gap: ${theme.spacing.x2}px;
  `,
)

const AddressDetails = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-flow: column;
    color: ${theme.colors.bodyTextColor};
    gap: ${theme.spacing.x1}px;
  `,
)

const IconWrapper = styled.div(
  ({ theme }) => css`
    width: ${theme.spacing.x3}px;
    display: flex;
    align-items: center;
  `,
)

const Follow = styled.p(
  ({ theme }) => css`
    font-weight: bold;
    margin-top: ${theme.spacing.x4}px;
    margin-bottom: ${theme.spacing.x1}px;
  `,
)

const SocialLinks = styled.div(
  ({ theme }) => css`
    display: flex;
    gap: ${theme.spacing.x2}px;
  `,
)

interface Props {
  title: string
  contacts: Array<{
    key: string
    name: string
    email: string
    phone?: string
  }>
  addressInfo?: Array<{
    id: string
    title?: string
    streetname: string
    housenumber: string
    postcode: string
    city: string
    googleLink?: string
  }>
  followUs: string
  socialLinks?: Array<{
    id: string
    href: string
    target?: string
    icon: string
  }>
}

const ContactCard = ({
  title,
  contacts,
  addressInfo,
  followUs,
  socialLinks,
}: Props) => (
  <Contacts>
    <ContactTitle>{title}</ContactTitle>
    <ContactDetails>
      {contacts.map(({ name, email, phone }) => (
        <Contact key={name}>
          <ContactName>{name}</ContactName>
          <ContactDetails>
            <ContactInfo href={`mailto:${email}`}>
              <IconWrapper>
                <Email />
              </IconWrapper>
              {email}
            </ContactInfo>
            {phone && (
              <ContactInfo href={`tel:${phone}`}>
                <IconWrapper>
                  <Phone />
                </IconWrapper>
                {phone}
              </ContactInfo>
            )}
          </ContactDetails>
        </Contact>
      ))}
    </ContactDetails>
    {addressInfo &&
      addressInfo.map(
        ({
          id,
          title,
          streetname,
          housenumber,
          postcode,
          city,
          googleLink,
        }) => (
          <Address key={id}>
            {title && <AddressTitle>{title}</AddressTitle>}
            <AddressDetails>
              {googleLink && (
                <AddressInfoLink href={googleLink} target="_blank">
                  <IconWrapper>
                    <Location />
                  </IconWrapper>
                  {streetname} {housenumber}, {postcode} {city}
                </AddressInfoLink>
              )}
              {!googleLink && (
                <AddressInfo>
                  <IconWrapper>
                    <Location />
                  </IconWrapper>
                  {streetname} {housenumber}, {postcode} {city}
                </AddressInfo>
              )}
            </AddressDetails>
          </Address>
        ),
      )}
    {socialLinks && socialLinks.length > 0 && (
      <>
        <Follow>{followUs}</Follow>
        <SocialLinks>
          {socialLinks.map(({ id, href, target, icon }) => (
            <SocialIcon
              key={id}
              href={href}
              target={target}
              icon={icon}
              size="small"
            />
          ))}
        </SocialLinks>
      </>
    )}
  </Contacts>
)

export default ContactCard
