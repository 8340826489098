import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ReactNode } from 'react'
import ButtonLink from '../components/ButtonLink'
import PlaneDesktop from './assets/PlaneDesktop.svg'
import PlaneMobile from './assets/PlaneMobile.svg'

const PLANE_DESKTOP_CLASS = 'plane-desktop'
const PLANE_MOBILE_CLASS = 'plane-mobile'

const Container = styled.div(({ theme }) => [
  css`
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    z-index: 2;
    background: transparent;
    padding: ${theme.spacing.x8}px 0;
  `,
])

const ContainerWrapper = styled.div`
  max-width: 1140px;
  margin: 0 auto;
`

const StepHeading = styled.h3(({ theme }) => [
  css`
    color: ${theme.colors.primaryBlue};
  `,
])

const StepContent = styled.div(({ theme }) => [
  css`
    color: ${theme.colors.neutral2};
    text-align: center;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      text-align: left;
    }
  `,
])

const StepNumber = styled.div(({ theme }) => [
  css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    margin-bottom: ${theme.spacing.x2}px;
    background: ${theme.colors.sand2};
    color: ${theme.colors.neutral2};
    border-radius: 50%;
    text-align: center;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      margin-bottom: 0;
    }
  `,
])

const StepLine = styled.div(({ theme }) => [
  css`
    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      position: absolute;
      left: 50%;
      width: 1px;
      height: 100%;
      background: ${theme.colors.primaryBlue};
    }
  `,
])

const StepPoint = styled.div(({ theme }) => [
  css`
    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      position: absolute;
      left: 50%;
      width: 15px;
      height: 15px;
      transform: translateX(-50%);
      border-radius: 50%;
      background: ${theme.colors.midBlue};
    }
  `,
])

const StepIndicator = styled.div(({ theme }) => [
  css`
    position: relative;
    height: 100%;
    margin-right: 0;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      margin-right: ${theme.spacing.x2}px;
    }
  `,
])

const StepContentWrapper = styled.div(({ theme }) => [
  css`
    display: flex;
    width: 100%;
    height: 100%;
    flex-flow: column;
    align-items: center;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      align-items: flex-start;
    }
  `,
])

const Step = styled.div(({ theme }) => [
  css`
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: ${theme.spacing.x4}px 0;

    &:nth-of-type(1) {
      margin-top: ${theme.spacing.x4}px;
    }

    &:not(:last-of-type) {
      &::before {
        content: '';
        position: absolute;
        height: 1px;
        width: ${theme.spacing.x8}px;
        bottom: 0;
        background: ${theme.colors.sand2};
      }
    }

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      flex-flow: row;
      align-items: flex-start;
      max-width: 248px;
      margin-bottom: 0;
      padding: ${theme.spacing.x2}px 0;

      &:not(:last-of-type) {
        &::before {
          content: none;
        }
      }

      &:nth-of-type(even) {
        align-self: flex-start;
        justify-self: flex-end;
      }

      &:nth-of-type(odd) {
        ${StepLine} {
          height: calc(100% + 31px);
        }
      }

      &:nth-of-type(even) {
        ${StepLine} {
          top: -55px;
          height: 55px;
        }
      }

      &:nth-of-type(1) {
        margin-top: 0;
        grid-area: item-1;

        ${StepPoint} {
          top: calc(100% + 62px);
        }
      }
      &:nth-of-type(2) {
        grid-area: item-2;
        ${StepPoint} {
          top: -62px;
        }
      }
      &:nth-of-type(3) {
        grid-area: item-3;
        ${StepPoint} {
          top: calc(100% + 59px);
        }
      }
      &:nth-of-type(4) {
        grid-area: item-4;
        ${StepPoint} {
          top: -70px;
        }
      }
      &:nth-of-type(5) {
        grid-area: item-5;
        ${StepPoint} {
          top: calc(100% + 53px);
        }
      }
      &:nth-of-type(6) {
        grid-area: item-6;
        ${StepLine} {
          top: -77px;
          height: 77px;
        }
        ${StepPoint} {
          top: -77px;
        }
      }
    }
  `,
])

const Steps = styled.div(({ theme }) => [
  css`
    display: flex;
    flex-flow: column;
    width: 100%;

    &::before {
      content: '';
      display: block;
      position: absolute;
      left: -46px;
      width: 100vw;
      height: 54px;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzY4IiBoZWlnaHQ9IjUyIiB2aWV3Qm94PSIwIDAgMzY4IDUyIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMzY2LjczIDUxLjAwMDFDMjc1LjI5MSA1MS4wMDAxIDI3NS4yOTEgMC45OTk5OTYgMTgzLjg2NSAwLjk5OTk5MkM5Mi40Mzk3IDAuOTk5OTg4IDkyLjQzOTYgNTEgMC45OTk5OTggNTEiIHN0cm9rZT0iI0VEQkU2MiIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1kYXNoYXJyYXk9IjEwIDEwIi8+Cjwvc3ZnPgo=);
      background-repeat: repeat-x;
      background-position-x: center;
    }

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      grid-template-areas:
        'item-1 item-3 item-5'
        'item-2 item-4 item-6';
      grid-gap: ${theme.spacing.x8}px;

      &::before {
        top: 50%;
        left: calc((-1 * (100vw - 1140px) - 16px) / 2);
        transform: translateY(-50%);
      }
    }

    @media screen and (min-width: ${theme.breakpoints
        .desktop}px) and (max-width: 1200px) {
      &::before {
        left: -46px;
      }
    }
  `,
])

const StepsWrapper = styled.div`
  position: relative;
  width: 100%;
`

const CallToActionWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 2;
`

const CallToAction = styled(ButtonLink)`
  max-width: 420px;
  margin-top: -35px;
`

const Title = styled.h2(({ theme }) => [
  css`
    ${theme.text.heading2(theme)}
    position: relative;
    margin-bottom: ${theme.spacing.x2}px;
    text-align: center;
    margin-bottom: ${theme.spacing.x6}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      text-align: left;
    }

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      margin-bottom: ${theme.spacing.x9}px;
    }
  `,
])

const SvgAnimation = styled.div(({ theme }) => [
  css`
    height: 180px;
    overflow: hidden;
    margin-top: auto;
    bottom: 0;
    z-index: 0;
    text-align: center;
    width: 100%;

    .${PLANE_MOBILE_CLASS} {
      width: 100%;
      height: 217px;
    }

    .${PLANE_DESKTOP_CLASS} {
      display: none;
    }

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      margin-top: -150px;
      height: 356px;

      .${PLANE_DESKTOP_CLASS} {
        display: block;
        width: 100%;
        height: 727px;
      }

      .${PLANE_MOBILE_CLASS} {
        display: none;
      }
    }
  `,
])

export const Section = styled('section', {
  shouldForwardProp: (prop) => prop !== 'variant',
})<{ variant: ProcessWithStepsSectionVariant }>(({ theme, variant }) => [
  css`
    position: relative;
    overflow-x: hidden;
    background: ${theme.colors.white};
    padding: 0 ${theme.spacing.x2}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: 0 ${theme.spacing.x4}px;
    }
  `,
  variant === 'dark' &&
    css`
      margin-bottom: ${theme.spacing.x15}px;
      background: linear-gradient(
        180deg,
        ${theme.colors.lightBlue} 0,
        ${theme.colors.lightBlue} 20%,
        hsla(0, 0%, 100%, 0) 0
      );

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        margin-bottom: 0;
        padding: 0 ${theme.spacing.x4}px ${theme.spacing.x15}px
          ${theme.spacing.x4}px;
      }

      ${ContainerWrapper} {
        position: relative;

        &::before {
          content: '';
          width: 176px;
          height: 114px;
          background-color: ${theme.colors.white};
          position: absolute;
          top: -114px;
          right: -80px;
          z-index: 0;
        }

        &::after {
          content: '';
          width: 192px;
          height: 160px;
          background-color: ${theme.colors.sand1};
          position: absolute;
          top: -64px;
          right: -150px;
          z-index: 1;
        }
      }

      ${Container} {
        background: ${theme.colors.primaryBlue};
        padding: ${theme.spacing.x8}px ${theme.spacing.x3}px;
      }
      ${Title} {
        color: ${theme.colors.white};
      }
      ${Steps} {
        &::before {
          left: -64px;
        }
        @media screen and (min-width: ${theme.breakpoints.desktop}px) {
          &::before {
            left: calc((-1 * (100vw - 1140px) - 70px) / 2);
          }
        }

        @media screen and (min-width: ${theme.breakpoints
            .desktop}px) and (max-width: 1200px) {
          &::before {
            left: -76px;
          }
        }
      }
      ${Step} {
        &:not(:last-of-type) {
          &::before {
            background: ${theme.colors.green};
          }
        }
      }
      ${StepPoint} {
        background: ${theme.colors.green};
      }
      ${StepLine} {
        background: ${theme.colors.green};
      }
      ${StepNumber} {
        background: ${theme.colors.white};
        color: ${theme.colors.error};
      }
      ${StepHeading} {
        color: ${theme.colors.white};
      }
      ${StepContent} {
        color: ${theme.colors.white};
      }
    `,
])

export type ProcessWithStepsSectionVariant = 'light' | 'dark'

interface Props {
  title: ReactNode
  callToAction: {
    href: string
    target?: string
    text: string
  }
  steps: Array<{
    id: string
    title: string
    text: ReactNode
  }>
  variant: ProcessWithStepsSectionVariant
}

const ProcessWithStepsSection = ({
  title,
  steps,
  callToAction,
  variant,
}: Props) => (
  <Section variant={variant}>
    <ContainerWrapper>
      <Container>
        <Title>{title}</Title>
        <StepsWrapper>
          <Steps>
            {steps.map((step, index) => (
              <Step key={step.id}>
                <StepIndicator>
                  <StepNumber>{index + 1}</StepNumber>
                  <StepLine />
                  <StepPoint />
                </StepIndicator>
                <StepContentWrapper>
                  <StepHeading>{step.title}</StepHeading>
                  {step.text && <StepContent>{step.text}</StepContent>}
                </StepContentWrapper>
              </Step>
            ))}
          </Steps>
        </StepsWrapper>
      </Container>
    </ContainerWrapper>
    {variant === 'light' && (
      <SvgAnimation>
        <PlaneDesktop />
        <PlaneMobile />
      </SvgAnimation>
    )}
    {callToAction && variant === 'dark' && (
      <CallToActionWrapper>
        <CallToAction
          href={callToAction.href}
          target={callToAction.target}
          variant="secondary"
        >
          {callToAction.text}
        </CallToAction>
      </CallToActionWrapper>
    )}
  </Section>
)

export default ProcessWithStepsSection
