import { PartnersSectionFragment } from '../graphql/partnersSectionFragment.generated'
import PartnersSection, {
  PartnersSectionVariant,
} from '../sections/PartnersSection'
import convertDatoImage from '../utils/convertDatoImage'

interface Props {
  content: PartnersSectionFragment
}

const PartnersSectionBlock = ({
  content: { title, partners, variant },
  ...others
}: Props) => {
  return (
    <PartnersSection
      title={title || undefined}
      partners={
        partners.map(({ id, image, name, functionTitle }) => ({
          key: id,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          image: convertDatoImage(image!),
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          name: name!,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          functionTitle: functionTitle || undefined,
        })) || undefined
      }
      variant={variant as PartnersSectionVariant}
      {...others}
    />
  )
}

export default PartnersSectionBlock
