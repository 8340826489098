/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { SiteLocale } from '../graphql/datoSchema.generated'
import { currentSite, Site } from '../sites'

// eslint-disable-next-line consistent-return
export const getLocales = (): SiteLocale[] => {
  // eslint-disable-next-line default-case
  switch (currentSite) {
    case Site.Globetrotters:
      return process.env.LOCALES_GLOBETROTTERS!.split(',') as SiteLocale[]
    case Site.TheNextGlobetrotter:
      return process.env.LOCALES_THE_NEXT_GLOBETROTTER!.split(
        ',',
      ) as SiteLocale[]
  }
}
// eslint-disable-next-line consistent-return
export const getDefaultLocale = () => {
  // eslint-disable-next-line default-case
  switch (currentSite) {
    case Site.Globetrotters:
      return process.env.NEXT_PUBLIC_DEFAULT_LOCALE_GLOBETROTTERS! as SiteLocale
    case Site.TheNextGlobetrotter:
      return process.env
        .NEXT_PUBLIC_DEFAULT_LOCALE_THE_NEXT_GLOBETROTTER! as SiteLocale
  }
}
export const getHostname = () => process.env.NEXT_PUBLIC_HOSTNAME!
export const getHomepageSlug = () => process.env.NEXT_PUBLIC_HOMEPAGE_CMS_SLUG!
export const get404Slug = () => process.env.NEXT_PUBLIC_404_CMS_SLUG!
export const get500Slug = () => process.env.NEXT_PUBLIC_500_CMS_SLUG!

// eslint-disable-next-line consistent-return
export const getBuildTriggerId = (): string => {
  // eslint-disable-next-line default-case
  switch (currentSite) {
    case Site.Globetrotters:
      return process.env.DATOCMS_BUILD_TRIGGER_ID_GLOBETROTTERS!
    case Site.TheNextGlobetrotter:
      return process.env.DATOCMS_BUILD_TRIGGER_ID_THE_NEXT_GLOBETROTTER!
  }
}
