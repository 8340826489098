import { SimpleHeaderSectionFragment } from '../graphql/simpleHeaderSectionFragment.generated'
import SimpleHeaderSection from '../sections/SimpleHeaderSection'
import { usePageContext } from '../utils/PageContext'
import resolveLink from '../utils/resolveLink'

interface Props {
  content: SimpleHeaderSectionFragment
}

const SimpleHeaderSectionBlock = ({
  content: { title, callToAction },
  ...others
}: Props) => {
  const { siteConfig } = usePageContext()

  return (
    <SimpleHeaderSection
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      title={title!}
      callToAction={
        callToAction[0] && {
          ...resolveLink(siteConfig, callToAction[0]),
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          text: callToAction[0].text!,
        }
      }
      {...others}
    />
  )
}

export default SimpleHeaderSectionBlock
