import { NavigationBarSectionFragment } from '../graphql/navigationBarSectionFragment.generated'
import NavigationBarSection from '../sections/NavigationBarSection'
import { usePageContext } from '../utils/PageContext'
import convertDatoImage from '../utils/convertDatoImage'
import resolveLink from '../utils/resolveLink'

interface Props {
  content: NavigationBarSectionFragment
}

const NavigationBarSectionBlock = ({
  content: { logo, links, callToAction, goTo },
  ...others
}: Props) => {
  const { siteConfig } = usePageContext()

  return (
    <NavigationBarSection
      logo={convertDatoImage(logo)}
      links={links.map(({ link, subLinks }) => ({
        link: {
          key: link[0].id,
          ...resolveLink(siteConfig, link[0]),
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          text: link[0].text!,
        },
        subLinks:
          subLinks.map(({ link, brief }) => ({
            link: {
              key: link[0].id,
              ...resolveLink(siteConfig, link[0]),
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              text: link[0].text!,
            },
            brief: brief || undefined,
          })) || undefined,
      }))}
      callToAction={{
        ...resolveLink(siteConfig, callToAction[0]),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        text: callToAction[0].text!,
      }}
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      goTo={goTo!}
      {...others}
    />
  )
}

export default NavigationBarSectionBlock
