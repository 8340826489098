import RichText from '../components/RichText'
import { ProcessWithStepsSectionFragment } from '../graphql/processWithStepsSectionFragment'
import ProcessWithStepsSection, {
  ProcessWithStepsSectionVariant,
} from '../sections/ProcessWithStepsSection'
import { usePageContext } from '../utils/PageContext'
import resolveLink from '../utils/resolveLink'

interface Props {
  content: ProcessWithStepsSectionFragment
}

const ProcessWithStepsSectionBlock = ({
  content: { title, callToAction, steps, variant },
  ...others
}: Props) => {
  const { siteConfig } = usePageContext()
  if (!title || !variant) {
    return null
  }

  return (
    <ProcessWithStepsSection
      title={title}
      callToAction={
        callToAction[0] && {
          ...resolveLink(siteConfig, callToAction[0]),
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          text: callToAction[0].text!,
        }
      }
      steps={steps.map(({ id, title, text }) => {
        // eslint-disable-next-line default-case
        return {
          id,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          title: title!,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          text: <RichText text={text!} />,
        }
      })}
      variant={variant as ProcessWithStepsSectionVariant}
      {...others}
    />
  )
}

export default ProcessWithStepsSectionBlock
