import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { ContentGroupSectionFragment } from '../graphql/contentGroupSectionFragment.generated'
import BlockZone from '../components/BlockZone'
import blocks from '.'
import Continent from '../sections/assets/Continent.svg'
import Relocation from '../sections/assets/Relocation.svg'

const StyledAfricaBackgroundImage = styled(Continent)(
  ({ theme }) => css`
    position: absolute;
    top: ${theme.spacing.x6}px;
    left: 50%;
    width: 100%;
    height: auto;
    transform: translatex(-50%);
    z-index: ${theme.zIndex.base};

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      width: 1065px;
      top: 2px;
    }
  `,
)

const StyledRelocationBackgroundImage = styled(Relocation)(
  ({ theme }) => css`
    display: none;

    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      display: block;
      position: absolute;
      top: 80px;
      left: calc(50% - 585px);
      transform: translateX(-50%);
      height: calc(100% - 80px);
      z-index: ${theme.zIndex.relative1};
    }
  `,
)

export const Container = styled('div', {
  shouldForwardProp: (prop) => prop !== 'variant',
})<{ variant: ContentGroupSectionVariant }>(({ theme, variant }) => [
  css`
    position: relative;

    & > section {
      position: relative;
      z-index: ${theme.zIndex.relative1};
    }
  `,
  variant === 'africa-background' &&
    css`
      padding-top: ${theme.spacing.x17}px;
      background-color: ${theme.colors.lightBlue};
      background-image: linear-gradient(
          90deg,
          hsla(0, 0%, 100%, 0) 199px,
          #c6d9fa 0,
          #c6d9fa 200px
        ),
        linear-gradient(
          180deg,
          hsla(0, 0%, 100%, 0) 0,
          hsla(0, 0%, 100%, 0) 239px,
          #c6d9fa 0,
          #c6d9fa 240px
        );
      background-size: 200px 240px;
      background-repeat: repeat;
    `,
])

export type ContentGroupSectionVariant =
  | 'africa-background'
  | 'relocation-background'

interface Props {
  content: ContentGroupSectionFragment
}

const ContentGroupSectionBlock = ({ content: { variant, content } }: Props) => {
  if (content?.length <= 0) {
    return null
  }

  return (
    <Container variant={variant as ContentGroupSectionVariant}>
      <BlockZone components={blocks} content={content} />
      {variant === 'africa-background' && <StyledAfricaBackgroundImage />}
      {variant === 'relocation-background' && (
        <StyledRelocationBackgroundImage />
      )}
    </Container>
  )
}

export default ContentGroupSectionBlock
