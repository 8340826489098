import Head from 'next/head'
import { useRouter } from 'next/router'
import { jsonLdScriptProps } from 'react-schemaorg'

import { Organization } from 'schema-dts'
import { SiteConfig } from '../graphql/siteConfig'
import { usePageContext } from '../utils/PageContext'
import absolutify from '../utils/absolutify'
import { SiteLocale } from '../graphql/datoSchema.generated'
import resolveLink from '../utils/resolveLink'

type Props = Pick<SiteConfig, 'logo' | 'title' | 'socialLinks'>

const OrganizationRichSnippet = ({ logo, title, socialLinks }: Props) => {
  const { siteConfig } = usePageContext()
  const { defaultLocale } = useRouter()
  if (!defaultLocale) {
    return null
  }

  return (
    <Head>
      <script
        {...jsonLdScriptProps<Organization>({
          '@context': 'https://schema.org',
          '@type': 'Organization',
          '@id': absolutify('#organization', defaultLocale as SiteLocale),
          name: title || '',
          url: absolutify('/', defaultLocale as SiteLocale),
          logo: {
            '@type': 'ImageObject',
            url: logo?.url,
            '@id': absolutify('#organizationLogo', defaultLocale as SiteLocale),
            width: logo?.width,
            height: logo?.height,
          },
          image: {
            '@type': 'ImageObject',
            '@id': absolutify('#organizationLogo', defaultLocale as SiteLocale),
          },
          sameAs: socialLinks.map((link) => resolveLink(siteConfig, link).href),
        })}
      />
    </Head>
  )
}

export default OrganizationRichSnippet
