import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ReactNode } from 'react'
import { primaryButtonCss } from '../components/Button'
import Image from '../components/Image'
import ButtonLink from '../components/ButtonLink'

export const Section = styled.section(({ theme }) => [
  css`
    background: ${theme.colors.primaryBlue};
    display: flex;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      padding: 0 ${theme.spacing.x11}px;
    }
  `,
])

const Container = styled.div(
  ({ theme }) => css`
    width: ${theme.spacing.gridMaxWidth}px;
    margin: 0 auto;
    padding: ${theme.spacing.x5}px ${theme.spacing.x2}px;
    display: flex;
    flex-flow: row;
    align-items: center;

    /* This is not taking effect, backlog item created */
    justify-content: space-between;

    @media screen and (max-width: ${theme.breakpoints.tablet}px) {
      flex-flow: column;
    }

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: ${theme.spacing.x5}px ${theme.spacing.x4}px;
    }
  `,
)

const ImageWrapper = styled.div(({ theme }) => [
  css`
    max-width: 175px;

    @media screen and (max-width: ${theme.breakpoints.desktop}px) {
      img {
        width: 100%;
        height: auto;
      }
    }

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      margin-right: ${theme.spacing.x3}px;
    }

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      max-width: 100%;
    }
  `,
])

const CallToActionWrapper = styled.div(({ theme }) => [
  css`
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      align-items: flex-start;
      padding: ${theme.spacing.x2}px 0;
      max-width: 752px;
    }
  `,
])

const CallToAction = styled(ButtonLink)(({ theme }) => [
  primaryButtonCss(theme),
  css`
    background: ${theme.colors.green};
    width: 100%;
    max-width: 400px;
    display: flex;
    justify-content: space-between;
  `,
])

const Title = styled.h2(({ theme }) => [
  css`
    ${theme.text.heading2(theme)}
    ${theme.text.titleLine(theme)}
    color: white;
    position: relative;
    margin-bottom: ${theme.spacing.x2}px;
    text-align: center;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      text-align: left;
    }
  `,
])

interface Props {
  image?: ImageInfo
  title: ReactNode
  callToAction: {
    href: string
    target?: string
    text: string
  }
}

const CallToActionSection = ({ image, title, callToAction }: Props) => (
  <Section>
    <Container>
      <ImageWrapper>
        {image && <Image src={image} alt={image.alt || ''} width="175" />}
      </ImageWrapper>
      <CallToActionWrapper>
        <Title>{title}</Title>
        <CallToAction
          href={callToAction.href}
          target={callToAction.target}
          variant="textual"
        >
          {callToAction.text}
        </CallToAction>
      </CallToActionWrapper>
    </Container>
  </Section>
)

export default CallToActionSection
