import Script from 'next/script'

const RecaptchaLoader = () => (
  <Script
    id="googleRecaptcha"
    src="https://recaptcha.net/recaptcha/api.js?render=explicit"
    strategy="afterInteractive"
  />
)

export default RecaptchaLoader
