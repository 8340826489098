import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ReactNode } from 'react'

export const Container = styled.div`
  font-size: 17px;
`
export const Author = styled.div(
  ({ theme }) => css`
    color: ${theme.colors.primaryBlue};
    font-size: 0.7em;
    font-style: italic;
  `,
)

interface Props {
  children: ReactNode
  author: string
}

const Quote = ({ children, author }: Props) => (
  <Container>
    <div>{children}</div>
    <Author>- {author}</Author>
  </Container>
)

export default Quote
