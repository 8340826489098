import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { DateTime } from 'schema-dts'
import { useRouter } from 'next/router'
import { ParsedUrlQueryInput } from 'querystring'
import { useEffect, useState } from 'react'
import parseNextQuery from '../utils/parseNextQuery'
import NavigationCardsSection from './NavigationCardsSection'
import FilterBar from '../components/FilterBar'

const Section = styled.section(
  css`
    display: flex;
    width: 100%;
    justify-content: center;
  `,
)

const Container = styled.div(
  css`
    display: flex;
    flex-flow: column;
    width: 100%;
  `,
)

interface Props {
  filterBy: string
  filterAll: string
  pathname: string
  categories: string[]
  podcasts: Array<{
    id: string
    title: string
    image: ImageInfo
    subTitle?: string
    category: string
    soundcloudLink: string
    publishedDate: DateTime
  }>
}

const PodcastListingSection = ({
  filterBy,
  filterAll,
  pathname,
  categories,
  podcasts,
}: Props) => {
  const { query, replace } = useRouter()
  const replaceQuery = (newQuery: ParsedUrlQueryInput) => {
    replace(
      {
        pathname,
        query: {
          ...query,
          ...newQuery,
        },
      },
      undefined,
      {
        scroll: false,
      },
    )
  }

  const podcastCategories = categories.map((category) => ({
    label: category,
    value: category,
  }))

  const actualCategory = parseNextQuery(query.category)

  const [optimisticCategory, setSelectedCategory] = useState(actualCategory)

  useEffect(() => {
    setSelectedCategory(parseNextQuery(query.category))
  }, [query.category])

  const applyCategory = (newCategory: string[]) => {
    setSelectedCategory(newCategory)
    replaceQuery({ category: newCategory, page: 1 })
  }
  return (
    <Section>
      <Container>
        <FilterBar
          filterData={[
            {
              label: filterBy,
              placeholder:
                optimisticCategory.length !== 0
                  ? optimisticCategory[0]
                  : 'Categorie',
              onSelect: applyCategory,
              active: optimisticCategory,
              options: [{ label: filterAll, value: '' }, ...podcastCategories],
            },
          ]}
        />
        <NavigationCardsSection
          layout="list"
          cards={podcasts.map((podcast) => {
            return {
              __typename: 'referenceCard',
              key: podcast.id,
              image: podcast.image,
              title: podcast.title,
              subTitle: podcast.subTitle ?? '',
              link: {
                href: podcast.soundcloudLink,
                target: '_blank',
              },
            }
          })}
        />
      </Container>
    </Section>
  )
}

export default PodcastListingSection
