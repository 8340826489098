import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ReactNode } from 'react'
import Image from '../components/Image'
import { mediumButtonCss } from '../components/Button'

export const Section = styled.section(({ theme }) => [
  css`
    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: 0 ${theme.spacing.x4}px;
    }
  `,
])

const Container = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-flow: column;
    max-width: ${theme.spacing.gridMaxWidth}px;
    gap: ${theme.spacing.x4}px;
    padding: ${theme.spacing.x6}px ${theme.spacing.x2}px;
    margin: 0 auto;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: ${theme.spacing.x6}px 0;
    }

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      padding: ${theme.spacing.x10}px 0;
    }
  `,
)

const Row = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-flow: column;
    gap: ${theme.spacing.x6}px;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      flex-flow: row;
      gap: ${theme.spacing.x15}px;
    }
  `,
)

const LeftColumn = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-flow: column;
    flex-grow: 0;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      flex: 50%;
      flex-grow: 0;
    }
  `,
)

const Title = styled.h2(
  ({ theme }) => css`
    ${theme.text.heading2(theme)}
    color: ${theme.colors.primaryBlue};
  `,
)

const SubTitle = styled.h3(
  ({ theme }) => css`
    ${theme.text.heading3(theme)}
  `,
)

const RightColumn = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-flow: column;
    position: relative;
    align-items: center;

    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      width: 100%;
      flex: 50%;
      align-items: flex-start;
    }
  `,
)

const ImageWrapper = styled.div(
  ({ theme }) => css`
    position: relative;
    width: 100%;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      max-width: 400px;
    }

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      max-width: none;
    }

    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      max-width: 400px;
    }
  `,
)

const StyledImage = styled(Image)`
  width: 100%;
  height: auto;
`

const CallToActionBox = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-flow: column;
    position: relative;
    padding: ${theme.spacing.x4}px;
    z-index: ${theme.zIndex.relative5};
    background-color: ${theme.colors.green};
    transform: translateY(-${theme.spacing.x1}px);
    width: 100%;

    h3,
    h4 {
      color: ${theme.colors.white};
    }

    a {
      ${mediumButtonCss(theme)}
    }

    ::after {
      content: '';
      position: absolute;
      border-color: transparent transparent transparent ${theme.colors.green};
      border-style: solid;
      border-width: ${theme.spacing.x4}px 0 0 ${theme.spacing.x4}px;
      top: -${theme.spacing.x3}px;
      left: 50%;
    }

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      width: 285px;
      transform: translateX(-50%) translateY(-${theme.spacing.x3}px);
      margin-left: calc(50% + 142px);

      ::after {
        top: -${theme.spacing.x3}px;
        left: 0;
      }
    }

    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      width: 285px;
      transform: translateY(-${theme.spacing.x3}px);
      margin-left: 200px;

      ::after {
        top: -${theme.spacing.x3}px;
        left: 0;
      }
    }
  `,
)

interface Props {
  title: string
  subTitle?: string
  text: ReactNode
  image: ImageInfo
  callToAction?: ReactNode
}

const TextWithCallToActionSection = ({
  title,
  subTitle,
  text,
  image,
  callToAction,
}: Props) => (
  <Section>
    <Container>
      <Row>
        <LeftColumn>
          {subTitle && <SubTitle>{subTitle}</SubTitle>}
          <Title>{title}</Title>
          {text}
        </LeftColumn>
        <RightColumn>
          {image && (
            <ImageWrapper>
              <StyledImage
                src={image}
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                alt={image.alt!}
                width={554}
                height={412}
              />
            </ImageWrapper>
          )}
          {callToAction && <CallToActionBox>{callToAction}</CallToActionBox>}
        </RightColumn>
      </Row>
    </Container>
  </Section>
)

export default TextWithCallToActionSection
