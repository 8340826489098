import RichText from '../components/RichText'
import { CallToActionSectionFragment } from '../graphql/callToActionSectionFragment'
import CallToActionSection from '../sections/CallToActionSection'
import convertDatoImage from '../utils/convertDatoImage'
import { usePageContext } from '../utils/PageContext'
import resolveLink from '../utils/resolveLink'

interface Props {
  content: CallToActionSectionFragment
}

const CallToActionSectionBlock = ({
  content: { image, title, callToAction },
  ...others
}: Props) => {
  const { siteConfig } = usePageContext()

  return (
    <CallToActionSection
      image={convertDatoImage(image)}
      title={<RichText text={title} />}
      callToAction={{
        ...resolveLink(siteConfig, callToAction[0]),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        text: callToAction[0].text!,
      }}
      {...others}
    />
  )
}

export default CallToActionSectionBlock
