import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ReactNode } from 'react'
import Image from '../components/Image'
import ButtonLink from '../components/ButtonLink'
import { Container as QuoteContainer } from '../components/Quote'

const svg =
  'url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTQiIGhlaWdodD0iNTQiIHZpZXdCb3g9IjAgMCA1NCA1NCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMjciIGN5PSIyNyIgcj0iMjEuNSIgZmlsbD0id2hpdGUiIHN0cm9rZT0iIzgyRDQ1MyIgc3Ryb2tlLXdpZHRoPSIxMSIvPgo8cGF0aCBkPSJNMjcgNDhDMjUuMDkwMiA0NS4xNDQyIDIzLjA4MjIgNDMuMDA0IDE4IDQwSDM2QzMxLjA4MDMgNDMuMDYzNCAyOC45MzYgNDUuMjIyMSAyNyA0OFoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=)'
const Section = styled.section(({ theme }) => [
  css`
    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      --top-background-offset: 410px;
      background: ${svg}, ${svg}, ${svg}, ${svg},
        linear-gradient(
          to bottom,
          transparent var(--top-background-offset),
          ${theme.colors.lightBlue} var(--top-background-offset)
        );
      background-position: top 73px left 0, top 328px left -634px,
        bottom 94px left -120px, bottom 94px left 404px, 50%;
      background-repeat: repeat-x, repeat-x, repeat-x, repeat-x, repeat-x;
      background-size: 1440px 45px, 1440px 45px, 1440px 45px, 1440px 41px,
        100% 100%;
    }
    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      --top-background-offset: 510px;
    }
  `,
])
const Container = styled.div(
  ({ theme }) => css`
    max-width: ${theme.spacing.gridMaxWidth}px;
    margin: 0 auto;
    display: flex;
    flex-direction: column-reverse;
    gap: 0;
    justify-content: flex-end;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      flex-flow: row;
      gap: ${theme.spacing.x5}px;
    }
    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      gap: ${theme.spacing.x15}px;
    }
  `,
)
const LeftPane = styled.div(
  ({ theme }) => css`
    justify-self: flex-end;
    padding: ${theme.spacing.x5}px ${theme.spacing.x2}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: ${theme.spacing.x5}px ${theme.spacing.x4}px;
    }
    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      max-width: 480px;
    }
    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      padding: ${theme.spacing.x10}px ${theme.spacing.x4}px;
    }
  `,
)
const Title = styled.h1(
  ({ theme }) => css`
    ${theme.text.heading1(theme)}
    margin-bottom: ${theme.spacing.x3}px;
  `,
)
const Text = styled.div(
  ({ theme }) => css`
    font-size: 16px;
    p:first-of-type {
      margin-top: 0;
    }
    p:last-child {
      margin-bottom: 0;
    }

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      font-size: 24px;
    }
  `,
)
const Blue = styled.span(
  ({ theme }) => css`
    color: ${theme.colors.primaryBlue};
  `,
)
const CallToAction = styled(ButtonLink)(
  ({ theme }) => css`
    margin-top: ${theme.spacing.x3}px;
    width: auto;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      margin-top: ${theme.spacing.x5}px;
    }
    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      margin-top: ${theme.spacing.x9}px;
    }
  `,
)
const RightPane = styled.div`
  flex: 0 0 auto;
  overflow: hidden;
`
const CollageRow = styled.div(
  ({ theme }) => css`
    display: flex;
    justify-content: center;
    gap: 2px;
    margin-bottom: 2px;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      justify-content: flex-end;
    }
  `,
)
const CollageColumn = styled.div`
  position: relative;
`
const cardSizeMobile = 110
const cardSizeDesktop = 150
const cardSizeDesktopLarge = 210
const CollageImage = styled(Image, {
  shouldForwardProp: (prop) => prop !== 'size',
})<{ size: 'square' | 'small' }>(({ theme, size }) => [
  css`
    --card-size: ${cardSizeMobile}px;
    width: var(--card-size);
    height: var(--card-size);

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      --card-size: ${cardSizeDesktop}px;
    }
    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      --card-size: ${cardSizeDesktopLarge}px;
    }
  `,
  size === 'small' &&
    css`
      width: calc(var(--card-size) / 2);
    `,
])
const CollageImageText = styled.div(
  ({ theme }) => css`
    position: absolute;
    bottom: ${theme.spacing.x1 - 2}px; // -2px to compensate for line-height
    left: ${theme.spacing.x1}px;
    color: ${theme.colors.white};
    text-shadow: 0 2px 12px rgba(0, 0, 0, 0.6);
    font-size: 8px;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      bottom: ${theme.spacing.x2 - 4}px; // -4px to compensate for line-height
      left: ${theme.spacing.x2}px;
      font-size: 12px;
    }
    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      font-size: 16px;
    }
  `,
)
const CollageImageTitle = styled.div(
  ({ theme }) => css`
    font-size: 14px;
    line-height: 12px;
    font-weight: 600;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      line-height: 16px;
      font-size: 16px;
    }
    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      font-size: 20px;
    }
  `,
)
const CollageText = styled('div', {
  shouldForwardProp: (prop) => prop !== 'variant',
})<{ variant: 'blue' | 'green' | 'orange' }>(({ theme, variant }) => [
  css`
    --card-size: ${cardSizeMobile}px;
    width: var(--card-size);
    height: var(--card-size);
    padding: ${theme.spacing.x1}px;
    font-size: 6px;
    display: flex;
    align-items: center;

    p {
      margin: ${theme.spacing.x1}px 0;
    }
    p:first-of-type {
      margin: 0;
    }
    mark {
      background: none;
      font-size: 1.6em;
    }
    ${QuoteContainer} {
      font-size: 9px;
    }

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      --card-size: ${cardSizeDesktop}px;
      font-size: 8px;

      ${QuoteContainer} {
        font-size: 13px;
      }
    }
    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      --card-size: ${cardSizeDesktopLarge}px;
      padding: ${theme.spacing.x2}px;
      font-size: 11px;

      ${QuoteContainer} {
        font-size: 17px;
      }
    }
  `,
  variant === 'blue' &&
    css`
      background: #c6d9fa;
    `,
  variant === 'green' &&
    css`
      background: #e1fbd2;
    `,
  variant === 'orange' &&
    css`
      background: #f8d2c2;
    `,
])

interface Props {
  title: string
  subTitle?: string
  text?: ReactNode
  callToAction?: {
    href: string
    target?: string
    text: string
  }
  collageRows: Array<{
    columns: Array<{
      size: 'square' | 'small'
      content:
        | {
            image: ImageInfo
            title?: string
            subTitle?: string
          }
        | {
            text: ReactNode
            variant: 'blue' | 'green' | 'orange'
          }
    }>
  }>
}

const CollageHeader = ({
  title,
  subTitle,
  text,
  callToAction,
  collageRows,
}: Props) => (
  <Section>
    <Container>
      <LeftPane>
        <Title>
          {title} <Blue>{subTitle}</Blue>
        </Title>
        {text && <Text>{text}</Text>}
        {callToAction && (
          <CallToAction
            href={callToAction.href}
            target={callToAction.target}
            variant="primary"
          >
            {callToAction.text}
          </CallToAction>
        )}
      </LeftPane>
      <RightPane>
        {collageRows.map(({ columns }, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <CollageRow key={i}>
            {columns.map(({ size, content }, j) => (
              <CollageColumn
                // eslint-disable-next-line react/no-array-index-key
                key={j}
              >
                {'image' in content && (
                  <>
                    <CollageImage
                      src={content.image}
                      alt={content.title || ''}
                      size={size}
                      width={
                        size === 'square'
                          ? cardSizeDesktop
                          : cardSizeDesktop / 2
                      }
                      height={cardSizeDesktop}
                    />
                    <CollageImageText>
                      <CollageImageTitle>{content.title}</CollageImageTitle>
                      {content.subTitle}
                    </CollageImageText>
                  </>
                )}
                {'text' in content && (
                  <CollageText variant={content.variant}>
                    {content.text}
                  </CollageText>
                )}
              </CollageColumn>
            ))}
          </CollageRow>
        ))}
      </RightPane>
    </Container>
  </Section>
)

export default CollageHeader
