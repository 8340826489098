import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ReactNode } from 'react'
import Image from '../components/Image'

const FeatureTitle = styled.h3(({ theme }) => [
  css`
    ${theme.text.heading4(theme)}
    z-index: ${theme.zIndex.relative2};
    color: ${theme.colors.primaryBlue};
    margin-top: ${theme.spacing.x3}px;
  `,
])

const FeatureText = styled.div(({ theme }) => [
  css`
    z-index: ${theme.zIndex.relative2};
    margin-top: ${theme.spacing.x1}px;

    p:first-of-type {
      margin-top: 0;
    }
  `,
])

const ImageWrapper = styled.div(({ theme }) => [
  css`
    display: flex;
    align-items: center;
    z-index: ${theme.zIndex.relative1};
    width: 100px;
    height: 100px;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      &::before {
        content: '';
        position: absolute;
        z-index: ${theme.zIndex.base};
        top: -15px;
        left: -15px;
        width: 130px;
        height: 130px;
        background: ${theme.colors.white};
        border-radius: 50%;
      }
    }
  `,
])

const FeatureImage = styled(Image)(
  ({ theme }) => css`
    z-index: ${theme.zIndex.relative1};
    width: 100%;
    height: auto;
    max-height: 100%;
    object-fit: contain;
  `,
)

const FeatureContent = styled.div(({ theme }) => [
  css`
    position: relative;
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    padding: ${theme.spacing.x5}px ${theme.spacing.x3}px ${theme.spacing.x3}px
      ${theme.spacing.x3}px;
    background-color: ${theme.colors.white};

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      padding: 0;
      background-color: transparent;
    }
  `,
])

const Content = styled.div(({ theme }) => [
  css`
    position: relative;
    display: flex;
    margin-bottom: ${theme.spacing.x8}px;

    h2 {
      color: ${theme.colors.primaryBlue};
    }

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      width: 33.33%;
      margin-bottom: 0;
    }
  `,
])

const Feature = styled.div(({ theme }) => [
  css`
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 268px;

    ${ImageWrapper} {
      margin-bottom: -50px;
    }

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      width: auto;
      align-items: flex-start;

      ${ImageWrapper} {
        margin-bottom: 0;
      }
    }
  `,
])

const Features = styled.div(({ theme }) => [
  css`
    display: grid;
    gap: ${theme.spacing.x2}px;
    grid-template-columns: 268px;
    grid-auto-flow: column;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    overflow-x: scroll;
    margin: 0 -${theme.spacing.x2}px;
    scroll-padding-left: ${theme.spacing.x2}px;
    padding: 0 ${theme.spacing.x2}px;

    > * {
      scroll-snap-align: start;
    }

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      margin: 0 -${theme.spacing.x4}px;
      scroll-padding-left: ${theme.spacing.x4}px;
      padding: 0 ${theme.spacing.x4}px;
    }

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      width: 66.66%;
      gap: ${theme.spacing.x8}px ${theme.spacing.x4}px;
      margin: 0;
      padding: 0;
      justify-content: center;
      overflow: visible;
      grid-template-rows: 1fr 1fr;
      grid-template-columns: repeat(2, 1fr);

      > * {
        width: auto;
      }
    }
  `,
])

const Container = styled.div(({ theme }) => [
  css`
    position: relative;
    display: flex;
    flex-flow: column;
    max-width: ${theme.spacing.gridMaxWidth}px;
    margin: 0 auto;
    padding: ${theme.spacing.x8}px 0;
    background: transparent;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      flex-flow: row;
      gap: ${theme.spacing.x15}px;
      padding: ${theme.spacing.x12}px 0;
    }
  `,
])

export const Section = styled.section(({ theme }) => [
  css`
    position: relative;
    background: ${theme.colors.lightBlue};
    padding: 0 ${theme.spacing.x2}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: 0 ${theme.spacing.x4}px;
    }
  `,
])

const FeatureComponent = ({
  image,
  text,
  title,
}: {
  title: string
  text: ReactNode
  image: ImageInfo
}) => (
  <Feature>
    <ImageWrapper>
      <FeatureImage
        src={image}
        alt={image.alt || ''}
        width={100}
        height={100}
      />
    </ImageWrapper>
    <FeatureContent>
      <FeatureTitle>{title}</FeatureTitle>
      <FeatureText>{text}</FeatureText>
    </FeatureContent>
  </Feature>
)

interface Props {
  text: ReactNode
  features: Array<{
    id: string
    title: string
    text: ReactNode
    image: ImageInfo
  }>
}

const FeaturesSection = ({ text, features }: Props) => (
  <Section>
    <Container>
      <Content>{text}</Content>
      <Features>
        {features.map(({ id, title, text, image }) => (
          <FeatureComponent key={id} title={title} text={text} image={image} />
        ))}
      </Features>
    </Container>
  </Section>
)

export default FeaturesSection
