import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ReactNode } from 'react'
import Image from '../components/Image'
import { Container as QuoteContainer } from '../components/Quote'

const svg = `url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTQiIGhlaWdodD0iNTQiIHZpZXdCb3g9IjAgMCA1NCA1NCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMjciIGN5PSIyNyIgcj0iMjEuNSIgZmlsbD0id2hpdGUiIHN0cm9rZT0iIzgyRDQ1MyIgc3Ryb2tlLXdpZHRoPSIxMSIvPgo8cGF0aCBkPSJNMjcgNDhDMjUuMDkwMiA0NS4xNDQyIDIzLjA4MjIgNDMuMDA0IDE4IDQwSDM2QzMxLjA4MDMgNDMuMDYzNCAyOC45MzYgNDUuMjIyMSAyNyA0OFoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=)`
const Section = styled.section(({ theme }) => [
  css`
    --top-background-offset: 300px;
    background-image: ${svg}, ${svg}, ${svg}, ${svg}, ${svg},
      linear-gradient(
        to bottom,
        transparent var(--top-background-offset),
        ${theme.colors.lightBlue} var(--top-background-offset)
      );
    background-position: top 189px left -602px, left -385px top 16px,
      top 103px left -443px, bottom 0 left -378px, top 441px left -541px, 50%;
    background-repeat: repeat-x, repeat-x, repeat-x, repeat-x, repeat-x,
      repeat-x;
    background-size: 1440px 32px, 1440px 32px, 1440px 32px, 1440px 32px,
      1440px 32px, 100% 100%;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      background-position: bottom 0 left -650px, left -490px top 46px,
        top 29px left -83px, bottom 0 left 404px, top 133px left 620px, 50%;
      background-size: 1440px 54px, 1440px 54px, 1440px 36px, 1440px 36px,
        1440px 54px, 100% 100%;
    }
  `,
])
const Container = styled.div(
  ({ theme }) => css`
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    position: relative;
    z-index: 0;
    overflow: hidden;
    padding-bottom: ${theme.spacing.x4}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      flex-wrap: nowrap;
      padding: ${theme.spacing.x4}px 0;
      overflow-x: auto; // this could be auto if we want to make it scrollable
      scroll-snap-type: x mandatory;
      > * {
        scroll-snap-align: start;
      }
    }
  `,
)
const imageSizes = [
  [278, 278],
  [252, 180],
  [335, 372],
  [254, 216],
  [318, 276],
]
const CollageColumn = styled.div(
  ({ theme }) => css`
    flex: 0 0 auto;
    position: relative;

    ::before,
    ::after {
      content: '';
      position: absolute;
      z-index: -1;
    }

    :nth-of-type(5n + 1) {
      margin-top: 0;
      width: 50vw;
      height: ${(imageSizes[0][1] / imageSizes[0][0]) * 50}vw;

      ::before {
        width: 100px;
        height: 56px;
        bottom: -56px;
        left: 0;
        background-color: #f8d2c2;
      }
    }
    :nth-of-type(5n + 2) {
      margin-top: 129px;
      width: 50vw;
      height: ${(imageSizes[1][1] / imageSizes[1][0]) * 50}vw;

      ::before {
        width: 126px;
        height: 139px;
        top: -43px;
        right: 36px;
        background-color: #82d453;
      }
    }
    :nth-of-type(5n + 3) {
      margin-top: -40px;
      --ofset: 20px;
      margin-left: var(--ofset);
      width: calc(50vw - var(--ofset));
      height: calc(
        ${imageSizes[2][1] / imageSizes[2][0]} * (50vw - var(--ofset))
      );

      ::before {
        width: 130px;
        height: 156px;
        bottom: -35px;
        right: -37px;
        background-color: #5f9fff;
      }
    }
    :nth-of-type(5n + 4) {
      display: none;
    }
    :nth-of-type(5n + 5) {
      width: 50vw;
      margin-top: 40px;
      height: calc(${imageSizes[4][1] / imageSizes[4][0]} * 50vw);

      ::before {
        width: 191px;
        height: 61px;
        bottom: -61px;
        left: 70px;
        background-color: #ef7b4e;
      }
    }
    :nth-of-type(n + 6) {
      display: none;
    }

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      :nth-of-type(5n + 1) {
        margin-top: 61px;
        width: ${imageSizes[0][0]}px;
        height: ${imageSizes[0][1]}px;

        ::before {
          width: 100px;
          height: 56px;
          bottom: -56px;
          left: 0;
        }
        &:not(:last-of-type) {
          ::after {
            width: 263px;
            height: 110px;
            bottom: -110px;
            left: 100px;
            background-color: #dfecff;
          }
        }
      }
      :nth-of-type(5n + 2) {
        margin-top: 216px;
        width: ${imageSizes[1][0]}px;
        height: ${imageSizes[1][1]}px;

        ::before {
          width: 256px;
          height: 180px;
          top: -191px;
          right: -66px;
        }
      }
      :nth-of-type(5n + 3) {
        margin-top: 84px;
        margin-left: 0;
        width: ${imageSizes[2][0]}px;
        height: ${imageSizes[2][1]}px;
      }
      :nth-of-type(5n + 4) {
        display: block;
        margin-top: 0px;
        width: ${imageSizes[3][0]}px;
        height: ${imageSizes[3][1]}px;

        ::before {
          width: 186px;
          height: 73px;
          top: 58px;
          right: -186px;
          background-color: rgba(248, 210, 194, 0.50196);
        }
      }
      :nth-of-type(5n + 5) {
        margin-top: 132px;
        width: ${imageSizes[4][0]}px;
        height: ${imageSizes[4][1]}px;
      }
      :nth-of-type(n + 6) {
        display: block;
      }
    }
  `,
)
const CollageImage = styled(Image)`
  width: 100%;
  height: 100%;
`
const CollageImageText = styled.div(
  ({ theme }) => css`
    position: absolute;
    bottom: ${theme.spacing.x2 - 2}px; // -2px to compensate for line-height
    left: ${theme.spacing.x2}px;
    color: ${theme.colors.white};
    text-shadow: 0 2px 12px rgba(0, 0, 0, 0.6);
    font-size: 12px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      bottom: ${theme.spacing.x4 - 7}px; // -7px to compensate for line-height
      font-size: 18px;
    }
  `,
)
const CollageImageTitle = styled.div(
  ({ theme }) => css`
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      font-size: 25px;
    }
  `,
)
const CollageText = styled('div', {
  shouldForwardProp: (prop) => prop !== 'variant',
})<{ variant: 'blue' | 'green' | 'orange' }>(({ theme, variant }) => [
  css`
    width: 100%;
    padding: ${theme.spacing.x1}px;
    font-size: 6px;
    display: flex;
    align-items: center;

    p {
      margin: ${theme.spacing.x1}px 0;
    }
    p:first-of-type {
      margin: 0;
    }
    mark {
      background: none;
      font-size: 1.6em;
    }
    ${QuoteContainer} {
      font-size: 9px;
    }

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      font-size: 8px;

      ${QuoteContainer} {
        font-size: 13px;
      }
    }
    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      padding: ${theme.spacing.x2}px;
      font-size: 16px;

      ${QuoteContainer} {
        font-size: 17px;
      }
    }
  `,
  variant === 'blue' &&
    css`
      background: #c6d9fa;
    `,
  variant === 'green' &&
    css`
      background: #e1fbd2;
    `,
  variant === 'orange' &&
    css`
      background: #f8d2c2;
    `,
])

interface Props {
  columns: Array<{
    size: 'square' | 'small'
    content:
      | {
          image: ImageInfo
          title?: string
          subTitle?: string
        }
      | {
          text: ReactNode
          variant: 'blue' | 'green' | 'orange'
        }
  }>
}

const CollageHeader = ({ columns }: Props) => (
  <Section>
    <Container>
      {[...columns, ...columns, ...columns].map(({ content }, index) => (
        <CollageColumn
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          data-hidden={index >= columns.length ? true : undefined}
        >
          {'image' in content && (
            <>
              <CollageImage
                src={content.image}
                alt={content.title || ''}
                width={imageSizes[index % imageSizes.length][0]}
                height={imageSizes[index % imageSizes.length][1]}
                priority
              />
              <CollageImageText>
                <CollageImageTitle>{content.title}</CollageImageTitle>
                {content.subTitle}
              </CollageImageText>
            </>
          )}
          {'text' in content && (
            <CollageText variant={content.variant}>{content.text}</CollageText>
          )}
        </CollageColumn>
      ))}
    </Container>
  </Section>
)

export default CollageHeader
