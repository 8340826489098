import { css } from '@emotion/react'
import styled from '@emotion/styled'

export const Container = styled.div``

const RatingWrapper = styled.div`
  display: inline-block;
`

const Rating = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'rating' && prop !== 'variant' && prop !== 'starSize',
})<Props>(({ theme, rating, variant, starSize }) => [
  css`
    --percent: calc(${rating} / 5 * 100%);

    display: inline-block;
    font-size: ${starSize}px;
    font-family: Times; // make sure ★ appears correctly
    line-height: 1;

    &::before {
      content: '★★★★★';
      letter-spacing: 1px;
      background: linear-gradient(
        90deg,
        ${variant === 'dark' ? theme.colors.white : theme.colors.primaryBlue}
          var(--percent),
        ${variant === 'dark' ? theme.colors.darkBlue : theme.colors.neutral1}
          var(--percent)
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  `,
])

interface Props {
  rating: number
  variant: 'light' | 'dark'
  starSize: number
}

const StarRating = ({ rating, variant, starSize }: Props) => (
  <Container title={`${rating} / 5`}>
    <RatingWrapper>
      <Rating rating={rating} variant={variant} starSize={starSize} />
    </RatingWrapper>
  </Container>
)

export default StarRating
