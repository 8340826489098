import { css } from '@emotion/react'
import styled from '@emotion/styled'

const Container = styled.div(
  css`
    position: relative;
    z-index: 0;

    ::before {
      content: '';
      position: absolute;
      width: 146px;
      height: 217px;
      right: 5%;
      top: -7px;
      background-color: #fff;
      z-index: -1;
    }
  `,
)
const Title = styled.h1(
  ({ theme }) =>
    css`
      && {
        ${theme.text.heading1(theme)}
        text-align: center;
        margin: 0;
      }
    `,
)
const SubTitle = styled.h1(
  ({ theme }) =>
    css`
      &&& {
        ${theme.text.heading1(theme)}
        color: ${theme.colors.primaryBlue};
        text-align: center;
        margin: 0;
      }
    `,
)

interface Props {
  title: string
  subTitle: string
}

const PageTitle = ({ title, subTitle }: Props) => (
  <Container>
    <Title>{title}</Title>
    <SubTitle>{subTitle}</SubTitle>
  </Container>
)

export default PageTitle
