import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FieldErrors, UseFormRegister, UseFormTrigger } from 'react-hook-form'
import Cancel from '../icons/Solid/Icon/Solid/Cancel.svg'

const FieldSet = styled.fieldset(
  ({ theme: { spacing } }) => css`
    border: 0;
    margin-bottom: ${spacing.x3}px;
    padding: 0;
  `,
)
const TextArea = styled('textarea', {
  shouldForwardProp: (prop) => prop !== 'error',
})<{ error?: boolean }>(
  ({ error, theme }) => css`
    border: none;
    border-radius: 3px;
    height: 172px;
    outline-color: ${error
      ? `${theme.colors.error}`
      : `${theme.colors.primaryBlue}`};
    padding: 12px ${theme.spacing.x2}px;
    resize: none;
    width: 100%;
    background-color: ${theme.colors.pageBackgroundColor};
    color: ${theme.colors.primaryBlue};

    ::-webkit-input-placeholder {
      color: ${theme.colors.neutral4};
    }

    :hover {
      border-color: ${theme.colors.neutral3};
    }
    :focus {
      outline-style: solid 3px;
    }
  `,
)

const ErrorIcon = styled(Cancel)(
  ({ theme }) => css`
    fill: ${theme.colors.error};
    margin-right: ${theme.spacing.x1}px;
  `,
)

const Error = styled.p(
  ({ theme }) => css`
    color: ${theme.colors.error};
    line-height: 1.57;
    margin: ${theme.spacing.x1}px 0 0;
  `,
)
const Input = styled('input', {
  shouldForwardProp: (prop) => prop !== 'error',
})<{ error?: boolean }>(
  ({ error, theme }) => css`
    border: none;
    border-radius: 3px;
    outline-color: ${error
      ? `${theme.colors.error}`
      : `${theme.colors.primaryBlue}`};
    padding: 12px ${theme.spacing.x2}px;
    width: 100%;
    background-color: ${theme.colors.pageBackgroundColor};
    color: ${theme.colors.primaryBlue};

    ::-webkit-input-placeholder {
      color: ${theme.colors.neutral4};
    }

    :hover {
      border-color: ${theme.colors.neutral3};
    }
    :focus {
      outline-style: solid 3px;
    }
  `,
)

export interface IFormInputs {
  [key: string]: string | boolean | number
  gRecaptchaResponse: string
}

export interface IFormFieldText {
  id: string
  label: string
  variation: string
  name: string
  placeholder: string
  pattern?: string | undefined
  required?: boolean | undefined
  errorMessage?: string | undefined
}

export interface FormFieldTextProps extends IFormFieldText {
  register: UseFormRegister<IFormInputs>
  errors: FieldErrors<IFormInputs>
  trigger: UseFormTrigger<IFormInputs>
}

const FormFieldText = ({
  variation,
  name,
  placeholder,
  pattern,
  required,
  errorMessage,
  errors,
  register,
  trigger,
}: FormFieldTextProps) => {
  if (name)
    return variation === 'textarea' ? (
      <FieldSet>
        <TextArea
          {...register(name, {
            ...(required && { required }),
            ...(pattern && { pattern: new RegExp(pattern) }),
          })}
          {...{
            'aria-invalid': Boolean(errors[name]),
            ...(placeholder && { placeholder }),
          }}
          onKeyUp={() => trigger(name)}
          error={Boolean(errors[name])}
        />
        {errors[name] && (
          <Error role="alert">
            <ErrorIcon />
            {errorMessage}
          </Error>
        )}
      </FieldSet>
    ) : (
      <FieldSet>
        <Input
          {...register(name, {
            ...(required && { required }),
            ...(pattern && { pattern: new RegExp(pattern) }),
          })}
          {...{
            'aria-invalid': Boolean(errors[name]),
            ...(placeholder && { placeholder }),
            ...(variation && { type: variation }),
          }}
          error={Boolean(errors[name])}
          onKeyUp={() => trigger(name)}
        />
        {errors[name] && (
          <Error role="alert">
            <ErrorIcon />
            {errorMessage}
          </Error>
        )}
      </FieldSet>
    )
  return null
}

export default FormFieldText
