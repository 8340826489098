import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { useEffect, useState } from 'react'
import { DateTime } from 'schema-dts'
import { useRouter } from 'next/router'
import { ParsedUrlQueryInput } from 'querystring'

import NavigationCardsSection from './NavigationCardsSection'
import parseNextQuery from '../utils/parseNextQuery'
import FilterBar from '../components/FilterBar'

export const articlesPerPage = 12

const Section = styled.section(
  css`
    display: flex;
    width: 100%;
    justify-content: center;
  `,
)
const Container = styled.div(
  css`
    display: flex;
    flex-flow: column;
    width: 100%;
  `,
)

interface Props {
  filterBy: string
  filterAll: string
  sortBy: string
  dateAsc: string
  dateDesc: string
  pathname: string
  activeFilter: string
  categories: string[]
  articles: Array<{
    id: string
    title: string
    image: ImageInfo
    subTitle?: string
    category: string
    publishDate: DateTime
    href: string
  }>
}

const NewsListingSection = ({
  filterBy,
  filterAll,
  sortBy,
  dateAsc,
  dateDesc,
  pathname,
  activeFilter,
  categories,
  articles,
}: Props) => {
  const { query, replace } = useRouter()
  const replaceQuery = (newQuery: ParsedUrlQueryInput) => {
    replace(
      {
        pathname,
        query: {
          ...query,
          ...newQuery,
        },
      },
      undefined,
      {
        scroll: false,
      },
    )
  }

  const articleCategories = categories.map((category) => ({
    label: category,
    value: category,
  }))
  const dateSortOptions = [
    { label: dateDesc, value: dateDesc },
    { label: dateAsc, value: dateAsc },
  ]

  const actualDateSort = parseNextQuery(query.dateSort)

  const [optimisticCategory, setSelectedCategory] = useState([activeFilter])
  const [optimisticDateSort, setSelectedDateSort] = useState(actualDateSort)

  useEffect(() => {
    setSelectedCategory(parseNextQuery(query.category))
  }, [query.category])
  useEffect(() => {
    setSelectedDateSort(parseNextQuery(query.dateSort))
  }, [query.dateSort])

  const applyCategory = (newCategory: string[]) => {
    setSelectedCategory(newCategory)
    replaceQuery({ category: newCategory, page: 1 })
  }
  const applyDateSort = (newDateSort: string[]) => {
    setSelectedDateSort(newDateSort)
    replaceQuery({ dateSort: newDateSort, page: 1 })
  }

  return (
    <Section>
      <Container>
        <FilterBar
          filterData={[
            {
              label: filterBy,
              placeholder:
                optimisticCategory.length !== 0
                  ? optimisticCategory[0]
                  : 'Categorie',
              onSelect: applyCategory,
              active: optimisticCategory,
              options: [{ label: filterAll, value: '' }, ...articleCategories],
            },
          ]}
          sort={{
            label: sortBy,
            placeholder:
              optimisticDateSort.length !== 0 ? optimisticDateSort[0] : 'Date',
            onSelect: applyDateSort,
            active: optimisticDateSort,
            options: dateSortOptions,
          }}
        />
        <NavigationCardsSection
          layout="list"
          cards={articles.map(({ id, image, title, subTitle, href }) => {
            return {
              __typename: 'NavigationCardsLinkCardRecord',
              key: id,
              image,
              title,
              subTitle: subTitle ?? '',
              link: {
                href,
              },
            }
          })}
        />
      </Container>
    </Section>
  )
}

export default NewsListingSection
