import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ReactNode } from 'react'
import Link from 'next/link'
import ButtonLink from '../components/ButtonLink'
import { primaryButtonCss } from '../components/Button'
import { Job } from '../utils/getVacanciesOverview'

export const Section = styled.section(({ theme }) => [
  css`
    display: flex;
    flex-flow: row;
    width: 100%;
    justify-content: center;
    padding: ${theme.spacing.x6}px 0;

    background: linear-gradient(
      180deg,
      hsla(0, 0%, 100%, 0) 0,
      hsla(0, 0%, 100%, 0) 15%,
      ${theme.colors.lightBlue} 0,
      ${theme.colors.lightBlue} 85%,
      hsla(0, 0%, 100%, 0) 0
    );
  `,
])

const Container = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-flow: column;
    position: relative;
    align-items: center;
    width: 100%;

    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      padding: 0;
      flex-flow: row;
      width: ${theme.spacing.gridMaxWidth}px;
    }
  `,
)

const LeftColumn = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-grow: 1;
    flex-flow: column;
    align-items: center;
    width: 100%;
    background-color: ${theme.colors.greenLight};
    padding: ${theme.spacing.x6}px ${theme.spacing.x6}px ${theme.spacing.x16}px
      ${theme.spacing.x6}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: ${theme.spacing.x6}px ${theme.spacing.x6}px
        ${theme.spacing.x16}px ${theme.spacing.x6}px;
    }

    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      min-height: 500px;
      padding: ${theme.spacing.x19}px;
      align-items: flex-start;
      justify-content: center;
    }
  `,
)

const Text = styled.div(
  ({ theme }) => css`
    max-width: 360px;
    text-align: center;

    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      text-align: left;
    }
  `,
)

const RightColumn = styled.div(
  ({ theme }) => css`
    position: relative;
    z-index: ${theme.zIndex.relative5};
    padding: ${theme.spacing.x2}px;
    transform: translateY(-${theme.spacing.x13}px);

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: ${theme.spacing.x2}px ${theme.spacing.x4}px;
    }

    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      transform: translateX(-${theme.spacing.x13}px);

      ::before {
        content: '';
        position: absolute;
        z-index: ${theme.zIndex.relative4};
        height: ${theme.spacing.x11}px;
        width: ${theme.spacing.x11}px;
        border: ${theme.spacing.x2}px solid #fff;
        border-radius: ${theme.spacing.x11}px;
        right: -${theme.spacing.x8}px;
      }
    }
  `,
)

export const Title = styled.h2(
  ({ theme }) => css`
    ${theme.text.heading2(theme)};
    color: ${theme.colors.primaryBlue};
    margin: 0 !important;
  `,
)

const Cards = styled.div(
  ({ theme }) => css`
    display: grid;
    gap: ${theme.spacing.x2}px;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
    }
    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      ::after {
        content: '';
        position: absolute;
        width: ${theme.spacing.x11}px;
        height: ${theme.spacing.x15}px;
        background-color: ${theme.colors.error};
        top: -${theme.spacing.x7}px;
        right: -${theme.spacing.x13}px;
      }
    }
  `,
)

const Card = styled.article(
  ({ theme }) => css`
    display: flex;
    flex-flow: column;
    background: ${theme.colors.white};
    box-shadow: 0px 4px ${theme.spacing.x2}px 0px rgba(38, 107, 238, 0.15);
    width: 100%;
    height: 100%;
    transition: transform 300ms ease-out;

    :hover {
      transform: translateY(-10px);
    }

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      width: 268px;
    }
  `,
)

const CardLink = styled(Link)(
  ({ theme }) => css`
    color: ${theme.colors.bodyTextColor};
    display: block;

    :hover {
      text-decoration: none;
    }
  `,
)

const CardContent = styled.div(
  ({ theme }) => css`
    padding: ${theme.spacing.x3}px;
  `,
)

const SubTitle = styled.div(
  ({ theme }) => css`
    font-weight: 500;
    line-height: 2;
    font-size: 12px;
    margin-bottom: ${theme.spacing.x0}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      font-size: 14px;
    }
  `,
)

const CardTitle = styled.h3(
  ({ theme }) =>
    css`
      font-size: 16px;
      color: ${theme.colors.primaryBlue};
      margin-bottom: ${theme.spacing.x1}px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        ${theme.text.heading4(theme)};
        color: ${theme.colors.primaryBlue};
      }
    `,
)

const CallToAction = styled(ButtonLink)(({ theme }) => [
  primaryButtonCss(theme),
  css`
    display: flex;
    margin-top: ${theme.spacing.x2}px;
  `,
])

interface Props {
  title: string
  text: ReactNode
  jobs: {
    items: Pick<Job, 'id' | 'functionName' | 'sector' | 'link'>[]
  }
  callToAction: {
    href: string
    target?: string
    text: string
  }
}

const JobFeedSection = ({ title, text, callToAction, jobs }: Props) => (
  <Section>
    <Container>
      <LeftColumn>
        <Title>{title}</Title>
        <Text>{text}</Text>
      </LeftColumn>
      <RightColumn>
        <Cards>
          {jobs &&
            jobs.items.map((job) => {
              if (!job.link) {
                return (
                  <Card key={job.id}>
                    <CardContent>
                      <CardTitle>{job.functionName}</CardTitle>
                    </CardContent>
                  </Card>
                )
              }
              return (
                <CardLink key={job.id} href={job.link}>
                  <Card key={`${job.id}card`}>
                    <CardContent>
                      <CardTitle>{job.functionName}</CardTitle>
                      <SubTitle>{job.sector}</SubTitle>
                    </CardContent>
                  </Card>
                </CardLink>
              )
            })}
        </Cards>
        <CallToAction
          href={callToAction.href}
          target={callToAction.target}
          variant="primary"
        >
          {callToAction.text}
        </CallToAction>
      </RightColumn>
    </Container>
  </Section>
)

export default JobFeedSection
