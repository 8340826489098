import { FooterSectionFragment } from '../graphql/footerSectionFragment'
import FooterSection from '../sections/FooterSection'
import { usePageContext } from '../utils/PageContext'
import convertDatoImage from '../utils/convertDatoImage'
import resolveLink from '../utils/resolveLink'

interface Props {
  content: FooterSectionFragment
}

const FooterSectionBlock = ({
  content: { logo, links, legalLinks, socialLinks },
  ...others
}: Props) => {
  const { siteConfig } = usePageContext()

  return (
    <FooterSection
      logo={convertDatoImage(logo)}
      links={links.map((link) => ({
        key: link.id,
        ...resolveLink(siteConfig, link),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        text: link.text!,
      }))}
      legalLinks={legalLinks.map((link) => ({
        key: link.id,
        ...resolveLink(siteConfig, link),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        text: link.text!,
      }))}
      socialLinks={socialLinks.map(({ icon, link }) => ({
        key: `Social-${icon}`,
        ...resolveLink(siteConfig, link[0]),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        icon: icon!,
      }))}
      {...others}
    />
  )
}

export default FooterSectionBlock
