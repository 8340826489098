import { EmployeeSectionFragment } from '../graphql/employeeSectionFragment.generated'
import EmployeeSection from '../sections/EmployeeSection'
import { usePageContext } from '../utils/PageContext'
import convertDatoImage from '../utils/convertDatoImage'
import resolveLink from '../utils/resolveLink'

interface Props {
  content: EmployeeSectionFragment
}

const EmployeeSectionBlock = ({
  content: { title, employees },
  ...others
}: Props) => {
  const { siteConfig } = usePageContext()

  return (
    <EmployeeSection
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      title={title!}
      employees={employees.map(
        ({ image, name, functionTitle, bio, email, phone }) => {
          return {
            /* eslint-disable @typescript-eslint/no-non-null-assertion */
            image: convertDatoImage(image!),
            name: name!,
            functionTitle: functionTitle!,
            bio: bio || undefined,
            email: email[0] ? resolveLink(siteConfig, email[0]) : undefined,
            phone: phone[0] ? resolveLink(siteConfig, phone[0]) : undefined,
          }
        },
      )}
      {...others}
    />
  )
}

export default EmployeeSectionBlock
