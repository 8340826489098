import { css } from '@emotion/react'
import styled from '@emotion/styled'
import Image from '../components/Image'
import Link from '../components/Link'
import Facebook from './assets/Facebook.svg'
import Instagram from './assets/Instagram.svg'
import LinkedIn from './assets/LinkedIn.svg'

export const Section = styled.section(({ theme }) => [
  css`
    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: 0 ${theme.spacing.x4}px;
    }
  `,
])
const Container = styled.div(
  ({ theme }) => css`
    max-width: ${theme.spacing.gridMaxWidth}px;
    margin: 0 auto;
    padding: ${theme.spacing.x5}px ${theme.spacing.x4}px;
    display: flex;
    flex-flow: column;
    gap: ${theme.spacing.x4}px;
    align-items: center;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: ${theme.spacing.x5}px 0;
    }

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      padding: ${theme.spacing.x8}px 0;
    }
  `,
)
const Row = styled.div(
  ({ theme }) => css`
    display: flex;
    gap: ${theme.spacing.x4}px;
    align-items: center;
    flex-flow: column;
    width: 100%;
    padding: ${theme.spacing.x2}px 0;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      align-items: flex-start;
      flex-flow: row;
      justify-content: space-between;
      padding: ${theme.spacing.x4}px 0;
    }
  `,
)
const FootNoteRow = styled.div(
  ({ theme }) => css`
    display: flex;
    gap: ${theme.spacing.x4}px;
    align-items: center;
    flex-flow: column-reverse;
    width: 100%;
    padding: ${theme.spacing.x2}px 0;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      flex-flow: row;
      justify-content: space-between;
      border-top: 1px solid ${theme.colors.neutral1};
      padding: ${theme.spacing.x4}px 0;
    }
  `,
)
const Menu = styled.menu(
  ({ theme }) => css`
    display: grid;
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      grid-template-columns: 1fr 1fr;
      width: 70%;
      margin-left: ${theme.spacing.x4}px;
      text-align: left;
    }

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      width: 50%;
    }
  `,
)
const LegalMenu = styled.menu(
  ({ theme }) => css`
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-flow: column;
    text-align: center;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      flex-flow: row;
    }
  `,
)
const MenuItem = styled.li(
  ({ theme }) => css`
    padding: 0 ${theme.spacing.x3}px;
  `,
)

const LegalMenuItem = styled.li(
  ({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      :not(:first-of-type) {
        padding-left: ${theme.spacing.x3}px;
      }
    }
  `,
)

const MenuLink = styled(Link)(
  ({ theme }) => css`
    ${theme.links.neutral3(theme)}
    display: inline-block;
    padding: ${theme.spacing.x2}px 0;
    border-bottom: 1px solid ${theme.colors.neutral1};

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      border-bottom: 0;
      font-size: 20px;
    }
  `,
)
const LegalMenuLink = styled(Link)(
  ({ theme }) => css`
    ${theme.links.blue(theme)}
    display: block;
    padding: ${theme.spacing.x2}px 0;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: 0;
    }
  `,
)
const SocialLinks = styled.div(
  ({ theme }) => css`
    display: flex;
    gap: ${theme.spacing.x4}px;
    align-items: center;
  `,
)

const SocialLink = styled(Link)(
  ({ theme }) => css`
    width: 40px;
    height: 40px;

    svg {
      width: 100%;
      height: 100%;
      transition: fill 0.2s ease-in-out;
    }

    :hover {
      svg {
        fill: ${theme.colors.neutral3};
      }
    }
  `,
)

type Icon = 'instagram' | 'facebook' | 'linkedin'

interface Props {
  logo?: ImageInfo
  links: Array<{
    key: string
    href: string
    target?: string
    text: string
  }>
  legalLinks: Array<{
    key: string
    href: string
    target?: string
    text: string
  }>
  socialLinks: Array<{
    key: string
    href: string
    target?: string
    icon: string
  }>
}

const SocialIcon = ({ icon }: { icon: Icon }) => {
  switch (icon) {
    case 'instagram':
      return <Instagram />

    case 'facebook':
      return <Facebook />

    case 'linkedin':
      return <LinkedIn />

    default:
      return null
  }
}

const FooterSection = ({ logo, links, legalLinks, socialLinks }: Props) => (
  <Section>
    <Container>
      <Row>
        {logo && (
          <Link href="/">
            <Image
              src={logo}
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              alt={logo.alt!}
              width={180}
              height={70}
            />
          </Link>
        )}
        <Menu>
          {links.map(({ key, href, target, text }) => (
            <MenuItem key={key}>
              <MenuLink href={href} target={target}>
                {text}
              </MenuLink>
            </MenuItem>
          ))}
        </Menu>
      </Row>
      <FootNoteRow>
        <LegalMenu>
          {legalLinks.map(({ key, href, target, text }) => (
            <LegalMenuItem key={key}>
              <LegalMenuLink href={href} target={target}>
                {text}
              </LegalMenuLink>
            </LegalMenuItem>
          ))}
        </LegalMenu>
        <SocialLinks>
          {socialLinks.map(({ key, href, target, icon }) => (
            <SocialLink key={key} href={href} target={target} aria-label={icon}>
              <SocialIcon icon={icon as Icon} />
            </SocialLink>
          ))}
        </SocialLinks>
      </FootNoteRow>
    </Container>
  </Section>
)

export default FooterSection
