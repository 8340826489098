import styled from '@emotion/styled'

const parseUrl = (url: string) => {
  const match = url.match(
    /(?:https?:\/\/)?(?:(?:(?:www\.?)?youtube\.com(?:\/(?:(?:watch\?.*?(v=[^&\s]+).*)|(?:v(\/.*))|(channel\/.+)|(?:user\/(.+))|(?:results\?(search_query=.+))))?)|(?:youtu\.be(\/.*)?))/,
  )
  return match?.[1]?.replace('v=', '') ?? undefined
}

const Iframe = styled.iframe`
  max-width: 100%;
  height: auto;
  aspect-ratio: 16 / 9;
`

interface Props {
  url: string
}

const YoutubeVideoPlayer = ({ url }: Props) => (
  <Iframe
    width="800"
    height="450"
    src={`https://www.youtube-nocookie.com/embed/${parseUrl(url)}`}
    title="YouTube video player"
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
    allowFullScreen
    loading="lazy"
  />
)

export default YoutubeVideoPlayer
