import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ReactNode } from 'react'
import Image from '../components/Image'
import ContactCard from '../components/ContactCard'
import ContactForm from '../components/ContactForm'
import { IFormFieldText } from '../components/FormFieldText'
import { IFormFieldSelect } from '../components/FormFieldSelect'

export const Section = styled.section(({ theme }) => [
  css`
    background-color: ${theme.colors.lightBlue};
    padding-bottom: ${theme.spacing.x14}px;
    overflow: hidden;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: 0 ${theme.spacing.x4}px ${theme.spacing.x14}px;
    }
  `,
])

const Container = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-flow: column;
    max-width: ${theme.spacing.gridMaxWidth}px;
    gap: ${theme.spacing.x4}px;
    padding: ${theme.spacing.x5}px ${theme.spacing.x2}px;
    margin: 0 auto;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: ${theme.spacing.x5}px 0;
    }

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      padding: ${theme.spacing.x20}px 0;
    }
  `,
)

const Row = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-flow: column;
    justify-content: space-between;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      flex-flow: row;
    }
  `,
)

const LeftColumn = styled.div(css`
  display: flex;
  flex-flow: column;
  margin-right: 175px;
  width: 100%;
`)

const RightColunn = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-flow: column;
    position: relative;
    width: 100%;

    ::after {
      content: '';
      position: absolute;
      left: ${theme.spacing.x20}px;
      top: ${theme.spacing.x6}px;
      width: 100%;
      height: 420px;
      background: ${theme.colors.greenLight};
    }

    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      flex-flow: row;
      padding-top: ${theme.spacing.x20}px;
    }
  `,
)

const ContactWrapper = styled.div(
  ({ theme }) => css`
    position: relative;
    top: ${theme.spacing.x10}px;
  `,
)

const StyledImage = styled(Image)(
  ({ theme }) => css`
    width: 100%;
    z-index: ${theme.zIndex.relative4};

    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      position: absolute;
      top: -${theme.spacing.x13}px;
      right: 0;
      width: 551px;
      height: 412px;
    }
  `,
)

interface Props {
  form: {
    formTitle: string
    text: ReactNode
    formFields?: (IFormFieldText | IFormFieldSelect)[] | undefined
    recaptcha: ReactNode
    privacyPolicy: ReactNode
    submitText: string
    succesText: ReactNode
    errorText: ReactNode
  }
  image?: ImageInfo
  title: string
  contacts: Array<{
    key: string
    name: string
    email: string
    phone?: string
  }>
  addressInfo: Array<{
    id: string
    title?: string
    streetname: string
    housenumber: string
    postcode: string
    city: string
    googleLink?: string
  }>
  followUs: string
  socialLinks?: Array<{
    id: string
    href: string
    target?: string
    icon: string
  }>
}

const ContactFormSection = ({
  form,
  image,
  title,
  contacts,
  addressInfo,
  followUs,
  socialLinks,
}: Props) => (
  <Section>
    <Container>
      <Row>
        <LeftColumn>
          <ContactForm {...form} />
        </LeftColumn>
        <RightColunn>
          <ContactWrapper>
            <ContactCard
              title={title}
              contacts={contacts}
              addressInfo={addressInfo}
              followUs={followUs}
              socialLinks={socialLinks}
            />
          </ContactWrapper>
          {image && (
            <StyledImage
              src={image}
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              alt={image.alt!}
              width={554}
              height={412}
            />
          )}
        </RightColunn>
      </Row>
    </Container>
  </Section>
)

export default ContactFormSection
