import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps, ReactNode } from 'react'

import { darkBackgroundVariantCss } from '../components/RichText'

export const Section = styled('section', {
  shouldForwardProp: (prop) => prop !== 'variant',
})<{ variant: 'light' | 'dark' }>(({ theme, variant }) => [
  css`
    background: ${theme.colors.lightBlue};

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: 0 ${theme.spacing.x4}px;
    }
  `,
  variant === 'dark' &&
    css`
      background: ${theme.colors.midBlue};
      ${darkBackgroundVariantCss(theme)}
    `,
])
const Container = styled.div(
  ({ theme }) => css`
    max-width: ${theme.spacing.gridMaxWidth}px;
    margin: 0 auto;
    padding: ${theme.spacing.x5}px ${theme.spacing.x2}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: ${theme.spacing.x10}px 0;
    }
  `,
)
const Contents = styled.div(() => [
  css`
    width: 100%;
    max-width: 800px;
    margin: 0 auto;

    > p:first-of-type {
      margin-top: 0;
    }
    > p:last-child {
      margin-bottom: 0;
    }
  `,
])

interface Props extends Omit<ComponentProps<typeof Section>, 'variant'> {
  children: ReactNode
  variant?: 'light' | 'dark'
}

const TextSection = ({ children, variant = 'light', ...others }: Props) => (
  <Section variant={variant} {...others}>
    <Container>
      <Contents>{children}</Contents>
    </Container>
  </Section>
)

export default TextSection
