import { useRouter } from 'next/router'
import { NavigationCardsSectionFragment } from '../graphql/navigationCardsSectionFragment.generated'
import NavigationCardsSection, {
  Layout,
} from '../sections/NavigationCardsSection'
import convertDatoImage from '../utils/convertDatoImage'
import notEmpty from '../utils/notEmpty'
import resolveLink from '../utils/resolveLink'
import { usePageContext } from '../utils/PageContext'

interface Props {
  content: NavigationCardsSectionFragment
}

const NavigationCardsSectionBlock = ({
  content: { title, layout, cards },
  ...others
}: Props) => {
  const { locale } = useRouter()
  const { siteConfig } = usePageContext()
  if (!cards) return null

  return (
    <NavigationCardsSection
      title={title || undefined}
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      layout={layout as Layout}
      cards={
        cards
          // eslint-disable-next-line array-callback-return, consistent-return
          .map((card) => {
            // eslint-disable-next-line default-case
            switch (card.__typename) {
              case 'NavigationCardsLinkCardRecord':
                if (!card.title) {
                  return null
                }

                return {
                  key: card.id,
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  image: convertDatoImage(card.image!),
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  title: card.title!,
                  subTitle: [card.subTitle ?? undefined].filter(notEmpty),
                  text: card.text || undefined,
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  link: resolveLink(siteConfig, card.link![0]),
                }

              case 'NavigationCardsReferenceCardRecord':
                if (!card.reference) {
                  return null
                }
                return {
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  key: card.id,
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  title: card.reference.title!,
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  image: convertDatoImage(card.reference.image!),
                  subTitle: [
                    card.reference.subTitle,
                    new Date(card.reference.publishedDate).toLocaleDateString(
                      locale,
                    ),
                  ].filter(notEmpty),
                  link: {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    href: card.reference.soundcloudLink!,
                    target: '_blank',
                  },
                }
              case 'NavigationCardsCallToActionCardRecord':
                return {
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  key: card.id,
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  title: card.title!,
                  text: card.text || undefined,
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  link: {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    ...resolveLink(siteConfig, card.link![0]),
                  },
                }
            }
          })
          .filter(notEmpty) || undefined
      }
      {...others}
    />
  )
}

export default NavigationCardsSectionBlock
