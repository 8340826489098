import { css, Interpolation, Theme } from '@emotion/react'
import { ButtonHTMLAttributes, ReactNode } from 'react'

export const primaryButtonCss = (theme: Theme) => css`
  padding: ${theme.spacing.x2}px ${theme.spacing.x3}px;
  font-size: 24px;
  font-weight: 450;
  background: ${theme.colors.buttonPrimaryBackground};
  color: ${theme.colors.buttonPrimaryColor};
  font-family: var(--font-hankenGrotesk);
  min-width: 278px;
  cursor: pointer;
  line-height: 1.6;
  font-weight: bold;
  border: 0;
  display: inline-block;

  transition: background 200ms ease-out;

  :hover {
    background: ${theme.colors.buttonPrimaryHoverBackground};
    color: ${theme.colors.buttonPrimaryHoverColor};
    text-decoration: none;
  }
  :disabled,
  [disabled] {
    background: ${theme.colors.buttonPrimaryDisabledBackground};
    color: ${theme.colors.buttonPrimaryDisabledColor};
    cursor: not-allowed;
  }
`

export const secondaryButtonCss = (theme: Theme) => css`
  ${primaryButtonCss(theme)}
  background: ${theme.colors.buttonSecondaryBackground};
  color: ${theme.colors.buttonSecondaryColor};

  :hover {
    background: ${theme.colors.buttonSecondaryHoverBackground};
    color: ${theme.colors.buttonSecondaryHoverColor};
  }
`

export const invertedButtonCss = (theme: Theme) => css`
  ${primaryButtonCss(theme)}
  background: ${theme.colors.buttonInvertedBackground};
  color: ${theme.colors.buttonInvertedColor};

  :hover {
    background: ${theme.colors.buttonInvertedHoverBackground};
    color: ${theme.colors.buttonInvertedHoverColor};
  }
  :disabled,
  [disabled] {
    background: ${theme.colors.buttonInvertedDisabledBackground};
    color: ${theme.colors.buttonInvertedDisabledColor};
  }
`

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const mediumButtonCss = (theme: Theme) => css`
  padding: ${theme.spacing.x2}px;
  font-size: 14px;

  @media screen and (min-width: ${theme.breakpoints.tablet}px) {
    font-size: 16px;
    min-width: 221px;
  }
`
export const invisibleButtonCss = () => css`
  border: 0;
  padding: 0;
`

export const speechButtonCss = (theme: Theme) => css`
  ${secondaryButtonCss(theme)}
  position: relative;
  transition: transform 0.2s linear;

  display: flex;
  justify-content: space-between;
  align-items: center;

  ::before {
    content: '';
    bottom: -${theme.spacing.x3}px;
    left: 0;
    position: absolute;
    border-color: ${theme.colors.buttonSecondaryBackground} transparent
      transparent;
    border-style: solid;
    border-width: ${theme.spacing.x4}px ${theme.spacing.x5}px 0 0;
  }

  :hover {
    transform: translateY(-5px);
    background: ${theme.colors.buttonSecondaryBackground};
    color: ${theme.colors.buttonSecondaryColor};
  }
`

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode
  // Needed for Storybook
  css?: Interpolation<Theme>
}

const Button = ({ children, type, ...others }: Props) => (
  // eslint-disable-next-line react/button-has-type
  <button type={type || 'button'} {...others}>
    {children}
  </button>
)

export default Button
