import RichText from '../components/RichText'
import { TestimonialsSectionFragment } from '../graphql/testimonialsSectionFragment'
import TestimonialsSection, {
  TestimonialsSectionVariant,
} from '../sections/TestimonialsSection'

interface Props {
  content: TestimonialsSectionFragment
}

const TestimonialsSectionBlock = ({
  content: {
    title,
    variant,
    ratingLabel,
    rating,
    positionsFilledLabel,
    positionsFilled,
    experience,
    experienceLabel,
    testimonials,
  },
  ...others
}: Props) => {
  return (
    <TestimonialsSection
      title={title}
      variant={variant as TestimonialsSectionVariant}
      ratingLabel={ratingLabel as string}
      rating={rating}
      positionsFilledLabel={positionsFilledLabel as string}
      positionsFilled={positionsFilled as string}
      experience={experience as string}
      experienceLabel={experienceLabel as string}
      testimonials={testimonials.map(({ id, company, author, text }) => {
        // eslint-disable-next-line default-case
        return {
          id,
          author: author as string,
          company: company as string | undefined,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          text: <RichText text={text!} />,
        }
      })}
      {...others}
    />
  )
}

export default TestimonialsSectionBlock
