import { ComponentProps } from 'react'
import RichText from '../components/RichText'
import { CollageHeaderFragment } from '../graphql/collageHeaderFragment.generated'
import CollageHeader from '../sections/CollageHeader'
import { usePageContext } from '../utils/PageContext'
import convertDatoImage from '../utils/convertDatoImage'
import resolveLink from '../utils/resolveLink'

interface Props {
  content: CollageHeaderFragment
}

const CollageHeaderBlock = ({
  content: { title, subTitle, text, callToAction, collageRows },
  ...others
}: Props) => {
  const { siteConfig } = usePageContext()

  if (!title) {
    return null
  }

  return (
    <CollageHeader
      title={title}
      subTitle={subTitle || undefined}
      text={text ? <RichText text={text} /> : undefined}
      callToAction={
        callToAction[0] && {
          ...resolveLink(siteConfig, callToAction[0]),
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          text: callToAction[0].text!,
        }
      }
      collageRows={
        collageRows.map((row) => ({
          // eslint-disable-next-line array-callback-return, consistent-return
          columns: row.columns.map(({ size, content }) => {
            // eslint-disable-next-line default-case
            switch (content[0].__typename) {
              case 'CollageImageRecord':
                return {
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  size: size!,
                  content: {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    image: convertDatoImage(content[0].image!),
                    title: content[0].title || undefined,
                    subTitle: content[0].subTitle || undefined,
                  },
                }
              case 'CollageTextRecord':
                return {
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  size: size!,
                  content: {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    text: <RichText text={content[0].text!} />,
                    variant: content[0].variant || undefined,
                  },
                }
            }
          }),
        })) as ComponentProps<typeof CollageHeader>['collageRows']
      }
      {...others}
    />
  )
}

export default CollageHeaderBlock
