// ! The elements in this file are used by both FormFieldSelect.tsx and Select.tsx,
// ! for forms and filters respectively. When changing styling in this file, make sure
// ! to check the effect on both components.

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import ArrowRight from '../icons/ArrowRight.svg'

export interface IFormVariantStyling {
  isForm: boolean
  useBlueTextColor: boolean
  useWhiteBackgroundColor: boolean
  validationError: boolean
}

const Container = styled.div<{ isOpen?: boolean }>(({ theme, isOpen }) => [
  css`
    position: relative;
    z-index: ${theme.zIndex.relative2};
    width: 100%;
  `,
  isOpen &&
    css`
      z-index: ${theme.zIndex.relative1};
    `,
])

const IconWrapper = styled.div<{
  isOpen: boolean
}>(({ theme, isOpen }) => [
  css`
    position: absolute;
    width: auto;
    right: ${theme.spacing.x2}px;

    svg {
      transform: rotate(90deg);
      transition: 300ms ease;
    }
  `,
  isOpen &&
    css`
      color: ${theme.colors.lightBlue};

      svg {
        transform: rotate(270deg);
        transition: 300ms ease;
      }
    `,
])

const StyledIcon = styled(ArrowRight)(
  ({ theme }) => css`
    color: ${theme.colors.primaryBlue};
  `,
)

const SelectWrapper = styled.div(
  css`
    display: flex;
    flex-flow: row;
    align-items: center;
  `,
)

const Select = styled.select<{
  variantStyling?: IFormVariantStyling
}>(({ theme, variantStyling }) => [
  css`
    align-items: center;
    background-image: url();
    background-color: ${theme.colors.lightBlue};
    border-color: ${theme.colors.pageBackgroundColor};
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: ${theme.spacing.x2}px ${theme.spacing.x6}px ${theme.spacing.x2}px
      ${theme.spacing.x2}px;
    transition: 300ms;
    width: 100%;
    word-break: break-word;

    :focus {
      outline: 0;
      border-color: ${theme.colors.primaryBlue};
    }
  `,
  variantStyling?.isForm &&
    css`
      background-color: ${theme.colors.white};
      border-color: ${variantStyling.validationError
        ? theme.colors.error
        : theme.colors.pageBackgroundColor};
      border-width: 2px;
      color: ${variantStyling.useBlueTextColor
        ? theme.colors.primaryBlue
        : theme.colors.neutral2};
    `,
])

export { Select, SelectWrapper, IconWrapper, StyledIcon, Container }
