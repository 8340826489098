// ! This file makes use of ../styles/Select.tsx, which is also used by FormFieldSelect.tsx
// ! Careful when making changes, test both implementations to see there are no problems.

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ChangeEvent, useState } from 'react'
import {
  Select as StyledSelect,
  SelectWrapper,
  IconWrapper,
  StyledIcon,
  Container,
} from '../styles/Select'

export const FormLabel = styled.span(
  ({ theme }) => css`
    ${theme.text.heading4(theme)};
    position: relative;
    display: block;
    margin-bottom: ${theme.spacing.x1}px;
  `,
)

export interface OptionType {
  label: string
  value: string
}

interface Props {
  label: string
  placeholder: string
  options: OptionType[] | undefined
  active: string[]
  onSelect: (item: string[]) => void
}

const SingleSelect = ({
  label,
  options,
  placeholder,
  onSelect,
  active,
  ...others
}: Props) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleClick = () => {
    setIsOpen(!isOpen)
  }

  const handleSelectOption = (e: ChangeEvent<HTMLSelectElement>) => {
    const newSelectedValues = Array.from(e.target.options)
      .filter((option) => option.selected)
      .map((option) => option.value)

    setIsOpen(!isOpen)
    onSelect(newSelectedValues)
  }

  return (
    <Container {...others}>
      {label && <FormLabel>{label}</FormLabel>}
      <SelectWrapper>
        <StyledSelect
          placeholder={placeholder}
          onChange={handleSelectOption}
          onClick={handleClick}
          onBlur={() => setIsOpen(false)}
          value={active[0] ?? ''}
        >
          {options &&
            options.map((option) => (
              <option
                key={option.value}
                value={option.value}
                defaultValue={active}
              >
                {option.label}
              </option>
            ))}
        </StyledSelect>
        <IconWrapper isOpen={isOpen}>
          <StyledIcon />
        </IconWrapper>
      </SelectWrapper>
    </Container>
  )
}

export default SingleSelect
