import { css } from '@emotion/react'
import styled from '@emotion/styled'
import SingleSelect, { OptionType } from './SingleSelect'

const FilterContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-flow: column;
    padding: ${theme.spacing.x2}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: ${theme.spacing.x2}px ${theme.spacing.x4}px;
    }
  `,
)
const FilterWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-flow: column;
    align-items: flex-end;
    width: 100%;
    max-width: ${theme.spacing.cardsContainerMaxWidth}px;
    margin: 0 auto;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      flex-flow: row;
      gap: ${theme.spacing.x2}px;
    }
  `,
)

const StyledSelect = styled(SingleSelect)(
  ({ theme }) => css`
    :not(:first-of-type) {
      margin-top: ${theme.spacing.x4}px;
    }

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      max-width: 260px;

      :not(:first-of-type) {
        margin-top: 0;
      }
    }
  `,
)

type SelectProps = {
  label?: string
  placeholder: string
  onSelect: (item: string[]) => void
  active: string[]
  options: OptionType[]
}

interface Props {
  filterData: SelectProps[]
  sort?: SelectProps
}

const FilterBar = ({ filterData, sort }: Props) => (
  <FilterContainer>
    <FilterWrapper>
      {filterData &&
        filterData.map(({ label, placeholder, onSelect, active, options }) => (
          <StyledSelect
            key={label}
            label={label || ''}
            placeholder={placeholder}
            onSelect={onSelect}
            active={active}
            options={options}
          />
        ))}
      {sort && (
        <StyledSelect
          key={sort.label}
          label={sort.label || ''}
          placeholder={sort.placeholder}
          onSelect={sort.onSelect}
          active={sort.active}
          options={sort.options}
        />
      )}
    </FilterWrapper>
  </FilterContainer>
)

export default FilterBar
