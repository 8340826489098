import RichText from '../components/RichText'
import { AboutSectionFragment } from '../graphql/aboutSectionFragment.generated'
import AboutSection from '../sections/AboutSection'
import { usePageContext } from '../utils/PageContext'
import convertDatoImage from '../utils/convertDatoImage'
import resolveLink from '../utils/resolveLink'

interface Props {
  content: AboutSectionFragment
}

const AboutSectionBlock = ({
  content: {
    title,
    subTitle,
    text,
    image,
    contactTitle,
    contacts,
    followUs,
    socialLinks,
    appointmentText,
  },
  ...others
}: Props) => {
  const { siteConfig } = usePageContext()

  if (!title || !contactTitle || !followUs) {
    return null
  }

  return (
    <AboutSection
      title={title}
      subTitle={subTitle || undefined}
      text={<RichText text={text} />}
      image={image ? convertDatoImage(image) : undefined}
      contactTitle={contactTitle}
      contacts={contacts.map((contact) => ({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        key: contact.id!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        name: contact.name!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        email: contact.email!,
        phone: contact.phone || undefined,
      }))}
      followUs={followUs}
      socialLinks={socialLinks.map(({ icon, link, id }) => ({
        id,
        ...resolveLink(siteConfig, link[0]),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        icon: icon!,
      }))}
      appointmentText={<RichText text={appointmentText} />}
      {...others}
    />
  )
}

export default AboutSectionBlock
