// ! This file makes use of ../styles/Select.tsx, which is also used by SingleSelect.tsx
// ! Careful when making changes, test both implementations to see there are no problems.
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import {
  Control,
  FieldErrors,
  UseFormRegister,
  UseFormTrigger,
  useWatch,
} from 'react-hook-form'
import Cancel from '../icons/Solid/Icon/Solid/Cancel.svg'
import {
  Select as StyledSelect,
  SelectWrapper,
  IconWrapper,
  StyledIcon,
  Container,
} from '../styles/Select'

const Title = styled.span(
  ({ theme: { colors, spacing } }) => css`
    display: block;
    font-family: var(--font-hankenGrotesk);
    font-size: 20px;
    font-weight: bold;
    margin-bottom: ${spacing.x3}px;
    position: relative;
    color: ${colors.primaryBlue};
  `,
)
const FieldSet = styled.fieldset(
  ({ theme: { spacing } }) => css`
    border: 0;
    margin-bottom: ${spacing.x3}px;
    padding: 0;
  `,
)
const Required = styled.span(
  ({ theme: { colors } }) => css`
    color: ${colors.error};
  `,
)
const ErrorIcon = styled(Cancel)(
  ({ theme }) => css`
    fill: ${theme.colors.error};
    margin-right: ${theme.spacing.x1}px;
  `,
)
const Error = styled.p(
  ({ theme }) => css`
    color: ${theme.colors.error};
    line-height: 1.57;
    margin: ${theme.spacing.x1}px 0 0;
  `,
)
interface IFormInputs {
  [key: string]: string | boolean | number
  gRecaptchaResponse: string
}

export interface IFormFieldSelect {
  id: string
  label: string
  options: string[]
  name: string
  placeholder: string
  required?: boolean | undefined
  errorMessage: string | undefined
}

export interface FormFieldSelectProps extends IFormFieldSelect {
  register: UseFormRegister<IFormInputs>
  errors: FieldErrors<IFormInputs>
  trigger: UseFormTrigger<IFormInputs>
  control: Control<IFormInputs & { gRecaptchaResponse: string }, unknown>
}

const FormFieldSelect = ({
  errors,
  errorMessage,
  label,
  name,
  options,
  placeholder,
  register,
  required,
  control,
}: FormFieldSelectProps) => {
  const defaultValue = ''
  const selectField = useWatch({
    control,
    name,
    defaultValue,
  })

  if (name)
    return (
      <FieldSet>
        <Title>
          {label}
          {required && <Required>*</Required>}
        </Title>
        <Container>
          <SelectWrapper>
            <StyledSelect
              {...register(name, {
                ...(required && { required }),
              })}
              {...{
                'aria-invalid': Boolean(errors[name]),
              }}
              defaultValue={defaultValue}
              variantStyling={{
                isForm: true,
                useBlueTextColor: selectField.valueOf() !== defaultValue,
                useWhiteBackgroundColor: true,
                validationError: Boolean(errors[name]),
              }}
            >
              <option value="" disabled>
                {placeholder}
              </option>
              {options?.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </StyledSelect>
            <IconWrapper isOpen={false}>
              <StyledIcon />
            </IconWrapper>
          </SelectWrapper>
          {errors[name] && (
            <Error role="alert">
              <ErrorIcon />
              {errorMessage}
            </Error>
          )}
        </Container>
      </FieldSet>
    )
  return null
}
export default FormFieldSelect
