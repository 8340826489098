import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ReactNode } from 'react'

const svg = `url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzQiIGhlaWdodD0iMzEiIHZpZXdCb3g9IjAgMCAzNCAzMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTQiIGN5PSIxNyIgcj0iOSIgZmlsbD0id2hpdGUiIHN0cm9rZT0iIzgyRDQ1MyIgc3Ryb2tlLXdpZHRoPSIxMCIvPgo8cGF0aCBkPSJNOSAxNUwxNCAyMEwyOCA2IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjExIiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPHBhdGggZD0iTTkgMTVMMTQgMjBMMjggNiIgc3Ryb2tlPSIjMjY2QkVFIiBzdHJva2Utd2lkdGg9IjYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPgo8L3N2Zz4K)`

export const List = styled.div(
  ({ theme }) => css`
    color: ${theme.colors.neutral2};
    font-size: 16px;

    ul {
      list-style: none;
      padding: 0;

      li {
        position: relative;
        margin-bottom: ${theme.spacing.x3}px;
        padding-left: 40px;

        // Workaround for ::marker bug in Safari: https://bugs.webkit.org/show_bug.cgi?id=204163
        ::before {
          content: ${svg};
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }

        p {
          display: inline-block;
          vertical-align: middle;
          padding-left: ${theme.spacing.x3}px;
        }
      }
    }

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      max-width: 342px;
    }
  `,
)

interface Props {
  children: ReactNode
}

const UspList = ({ children }: Props) => <List>{children}</List>

export default UspList
