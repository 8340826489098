import RichText from '../components/RichText'
import { TextWithCollageSectionFragment } from '../graphql/textWithCollageSectionFragment'
import TextWithCollageSection from '../sections/TextWithCollageSection'
import convertDatoImage from '../utils/convertDatoImage'

interface Props {
  content: TextWithCollageSectionFragment
}

const TextWithCollageSectionBlock = ({
  content: { title, text, images },
  ...others
}: Props) => {
  return (
    <TextWithCollageSection
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      title={title!}
      text={<RichText text={text} />}
      images={images.map((image) => convertDatoImage(image))}
      {...others}
    />
  )
}

export default TextWithCollageSectionBlock
