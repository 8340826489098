import RichText from '../components/RichText'
import { TextWithCallToActionSectionFragment } from '../graphql/textWithCallToActionSectionFragment.generated'
import TextWithCallToActionSection from '../sections/TextWithCallToActionSection'
import convertDatoImage from '../utils/convertDatoImage'

interface Props {
  content: TextWithCallToActionSectionFragment
}

const TextWithCallToActionSectionBlock = ({
  content: { title, subTitle, text, image, callToAction },
  ...others
}: Props) => {
  if (!title || !callToAction) {
    return null
  }

  return (
    <TextWithCallToActionSection
      title={title}
      subTitle={subTitle || undefined}
      text={<RichText text={text} />}
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      image={convertDatoImage(image!)}
      callToAction={<RichText text={callToAction} />}
      {...others}
    />
  )
}

export default TextWithCallToActionSectionBlock
