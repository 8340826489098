import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ReactNode, useState } from 'react'
import FormFieldSelect, { IFormFieldSelect } from './FormFieldSelect'
import FormFieldText, { IFormFieldText } from './FormFieldText'
import FormFieldTextWithConditional, {
  IFormFieldTextWithConditional,
} from './FormFieldTextWithConditional'
import { useRecaptchaForm } from '../utils/useRecaptchaForm'
import RecaptchaLoader from './RecaptchaLoader'
import RecaptchaContainer from './RecaptchaContainer'
import Checkbox from './Checkbox'
import ArrowRight from '../icons/ArrowRight.svg'
import { primaryButtonCss } from './Button'

export const Container = styled.div`
  font-size: 18px;
`

export const Form = styled.div(
  ({ theme }) => css`
    color: ${theme.colors.neutral2};
  `,
)

const Title = styled.h1(
  ({ theme }) => css`
    ${theme.text.heading1(theme)}
    color: ${theme.colors.primaryBlue};
    margin-bottom: ${theme.spacing.x2}px;
  `,
)

const Text = styled.div(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing.x3}px;
  `,
)

const Recaptcha = styled.div(
  ({ theme }) => css`
    background: ${theme.colors.neutral1};
    font-size: 11px;
    line-height: 1.45;
    padding: ${theme.spacing.x2}px;
    margin-bottom: ${theme.spacing.x3}px;

    p {
      margin: 0;
    }

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      font-size: 14px;
      line-height: 1.57;
      margin-bottom: ${theme.spacing.x3}px;
      padding: ${theme.spacing.x3}px;
    }
  `,
)
const Privacy = styled.label(
  ({ theme }) =>
    css`
      align-items: center;
      cursor: pointer;
      display: flex;
      font-size: 12px;
      margin-bottom: ${theme.spacing.x2}px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        font-size: 16px;
        margin-bottom: ${theme.spacing.x3}px;
      }
    `,
)

const StyledCheckbox = styled(Checkbox, {
  shouldForwardProp: (prop) => prop !== 'error',
})<{ error?: boolean }>(
  ({ error, theme }) => css`
    border: 1px solid
      ${error ? `${theme.colors.error}` : `${theme.colors.primaryBlue}`};
    margin-right: 12px;
  `,
)

const StyledRichText = styled.div(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing.x2}px;
  `,
)

const Submit = styled.button(
  ({ theme }) => css`
    ${primaryButtonCss(theme)};
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    line-height: 1.57;
    padding: ${theme.spacing.x2}px;
  `,
)

interface IFormInputs {
  [key: string]: string | boolean | number
}

interface Props {
  formTitle: string
  text: ReactNode
  formFields?: Array<IFormFieldText | IFormFieldSelect>
  recaptcha: ReactNode
  privacyPolicy: ReactNode
  submitText: string
  succesText: ReactNode
  errorText: ReactNode
}

const ContactForm = ({
  formTitle,
  text,
  formFields,
  recaptcha,
  privacyPolicy,
  submitText,
  succesText,
  errorText,
}: Props) => {
  const {
    recaptchaElementId,
    isLoading,
    getRecaptchaToken,
    register,
    handleSubmit,
    trigger,
    formState: { errors },
    control,
  } = useRecaptchaForm<IFormInputs>()

  const [isSubmitting, setIsSubmitting] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [greatSuccess, setGreatSuccess] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [formError, setFormError] = useState(false)

  const onSubmit = async (data: IFormInputs) => {
    setIsSubmitting(true)

    try {
      const recaptchaResponse = await getRecaptchaToken()

      const response = await fetch('/api/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          fields: data,
          ...recaptchaResponse,
        }),
      })
      const result = await response.json()

      if (!result.success) {
        setFormError(true)
        return
      }
      setGreatSuccess(true)
    } catch (err) {
      setFormError(true)
      throw err
    } finally {
      setIsSubmitting(false)
    }
  }

  if (greatSuccess) {
    return (
      <Container>
        <Title>{formTitle}</Title>
        <Text>{succesText}</Text>
      </Container>
    )
  }

  if (formError) {
    return (
      <Container>
        <Title>{formTitle}</Title>
        <Text>{errorText}</Text>
      </Container>
    )
  }

  return (
    <Container>
      <Title>{formTitle}</Title>
      {text && <Text>{text}</Text>}
      <form onSubmit={handleSubmit(onSubmit)}>
        {formFields &&
          formFields.map((field) => {
            if (!field.name || !field.label) {
              return null
            }
            if ((field as IFormFieldText).variation !== undefined) {
              const {
                id,
                label,
                variation,
                name,
                placeholder,
                errorMessage,
                dependentFieldName,
                dependentFieldValue,
                ...rest
              } = field as IFormFieldTextWithConditional
              if (dependentFieldName && dependentFieldValue)
                return (
                  <Form key={label}>
                    <FormFieldTextWithConditional
                      control={control}
                      {...rest}
                      id={id}
                      label={label}
                      variation={variation}
                      name={name}
                      placeholder={placeholder}
                      register={register}
                      errors={errors}
                      trigger={trigger}
                      errorMessage={errorMessage}
                      dependentFieldName={dependentFieldName}
                      dependentFieldValue={dependentFieldValue}
                    />
                  </Form>
                )

              return (
                <Form key={label}>
                  <FormFieldText
                    {...rest}
                    id={id}
                    label={label}
                    variation={variation}
                    name={name}
                    placeholder={placeholder}
                    register={register}
                    errors={errors}
                    trigger={trigger}
                    errorMessage={errorMessage}
                  />
                </Form>
              )
            }
            if ((field as IFormFieldSelect).options !== undefined) {
              const {
                id,
                label,
                name,
                placeholder,
                errorMessage,
                options,
                ...rest
              } = field as IFormFieldSelect
              return (
                <Form key={label}>
                  <FormFieldSelect
                    {...rest}
                    id={id}
                    label={label}
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    name={name!}
                    placeholder={placeholder}
                    options={options}
                    register={register}
                    errors={errors}
                    trigger={trigger}
                    errorMessage={errorMessage}
                    control={control}
                  />
                </Form>
              )
            }
            return null
          })}
        <Recaptcha>{recaptcha}</Recaptcha>
        <RecaptchaLoader />
        <RecaptchaContainer id={recaptchaElementId} />
        <Privacy>
          <StyledCheckbox
            {...register('privacyPolicy', { required: true })}
            as="div"
            type="checkbox"
            error={Boolean(errors.privacyPolicy)}
          />
          <StyledRichText>{privacyPolicy}</StyledRichText>
        </Privacy>
        <Submit disabled={isSubmitting || isLoading} type="submit">
          {submitText}
          <ArrowRight />
        </Submit>
      </form>
    </Container>
  )
}

export default ContactForm
