import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ReactNode } from 'react'
import Image from '../components/Image'
import { mediumButtonCss } from '../components/Button'
import ContactCard from '../components/ContactCard'

export const Section = styled.section(({ theme }) => [
  css`
    background-color: ${theme.colors.lightBlue};
    padding-bottom: ${theme.spacing.x14}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: 0 ${theme.spacing.x4}px ${theme.spacing.x14}px;
    }
  `,
])

const Container = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-flow: column;
    max-width: ${theme.spacing.gridMaxWidth}px;
    gap: ${theme.spacing.x4}px;
    padding: ${theme.spacing.x5}px ${theme.spacing.x2}px;
    margin: 0 auto;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: ${theme.spacing.x5}px 0;
    }

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      padding: ${theme.spacing.x10}px 0;
    }
  `,
)

const Row = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-flow: column;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      flex-flow: row;
    }
  `,
)

const LeftColumn = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-flow: column;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      margin-right: 175px;
    }
  `,
)

const Title = styled.h2(
  ({ theme }) => css`
    ${theme.text.heading2(theme)}
    color: ${theme.colors.primaryBlue};
  `,
)

const SubTitle = styled.h3(
  ({ theme }) => css`
    ${theme.text.heading3(theme)}
  `,
)

const RightColumn = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-flow: column;
    position: relative;

    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      flex-flow: row;
      padding-top: ${theme.spacing.x20}px;
      width: 100%;
    }
  `,
)

const StyledImage = styled(Image)(
  ({ theme }) => css`
    width: 100%;

    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      position: absolute;
      top: 0;
      right: 0;
      width: 551px;
      height: 412px;
    }
  `,
)

const AppointmentBox = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-flow: column;
    position: relative;
    padding: ${theme.spacing.x4}px;
    z-index: ${theme.zIndex.relative5};
    background-color: ${theme.colors.green};

    h3 {
      color: ${theme.colors.white};
    }

    a {
      ${mediumButtonCss(theme)}
    }

    ::after {
      content: '';
      position: absolute;
      border-width: 0 0 ${theme.spacing.x3}px ${theme.spacing.x6}px;
      border-style: solid;
      border-color: transparent transparent ${theme.colors.green};
      top: -${theme.spacing.x3}px;
      right: ${theme.spacing.x14}px;
    }

    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      width: 285px;
      transform: translate(-${theme.spacing.x6}px, ${theme.spacing.x13}px);

      ::after {
        content: '';
        display: inline-block;
        position: absolute;
        top: -${theme.spacing.x3}px;
        right: ${theme.spacing.x11}px;
        border-color: transparent transparent transparent ${theme.colors.green};
        border-style: solid;
        border-width: ${theme.spacing.x4}px 0 0 ${theme.spacing.x4}px;
      }
    }
  `,
)

interface Props {
  title: string
  subTitle?: string
  text?: ReactNode
  image?: ImageInfo
  contactTitle: string
  contacts: Array<{
    key: string
    name: string
    email: string
    phone?: string
  }>
  followUs: string
  socialLinks?: Array<{
    id: string
    href: string
    target?: string
    icon: string
  }>
  appointmentText?: ReactNode
}

export const AboutSectionImageProps = {
  width: 554,
  height: 412,
}

const AboutSection = ({
  title,
  subTitle,
  text,
  image,
  contactTitle,
  contacts,
  followUs,
  socialLinks,
  appointmentText,
}: Props) => (
  <Section>
    <Container>
      <Row>
        <LeftColumn>
          {subTitle && <SubTitle>{subTitle}</SubTitle>}
          <Title>{title}</Title>
          {text}
        </LeftColumn>
        <RightColumn>
          <ContactCard
            title={contactTitle}
            contacts={contacts}
            followUs={followUs}
            socialLinks={socialLinks}
          />
          {image && (
            <StyledImage
              src={image}
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              alt={image.alt!}
              width={AboutSectionImageProps.width}
              height={AboutSectionImageProps.height}
            />
          )}
          {appointmentText && (
            <AppointmentBox>{appointmentText}</AppointmentBox>
          )}
        </RightColumn>
      </Row>
    </Container>
  </Section>
)

export default AboutSection
