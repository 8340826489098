import { css } from '@emotion/react'
import styled from '@emotion/styled'
import ButtonLink from './ButtonLink'

const Card = styled.article(
  ({ theme }) => css`
    position: relative;
    display: flex;
    flex-flow: column;
    background: ${theme.colors.green};
    // Careful changing the size of the box-shadow. When it's bigger than the padding of the container, it will be cut off by the overflow used by the carousel.
    box-shadow: 0px 4px ${theme.spacing.x2}px 0px rgba(38, 107, 238, 0.15);
    transition: transform 300ms ease-out;

    :hover {
      transform: translateY(-10px);
    }
  `,
)
const CardContentWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-flow: column;
    align-items: space-between;
    height: 100%;
    padding: ${theme.spacing.x3}px;
  `,
)
const CardTitle = styled.h3(
  ({ theme }) =>
    css`
      ${theme.text.heading4(theme)}
      margin-bottom: ${theme.spacing.x1}px;
      color: ${theme.colors.white};
    `,
)
const Text = styled.div``
const CallToAction = styled(ButtonLink)(({ theme }) => [
  css`
    width: 100%;
    max-width: 400px;
    min-width: 0;
    display: flex;
    justify-content: space-between;
    margin-top: ${theme.spacing.x2}px;

    ::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  `,
])

interface Props {
  title: string
  text?: string
  link?: {
    href: string
    target?: string
    text?: string
  }
}

const NavigationCallToActionCard = ({ title, text, link }: Props) => (
  <Card>
    <CardContentWrapper>
      <div>
        <CardTitle>{title}</CardTitle>
        {text && <Text>{text}</Text>}
      </div>
      {link && (
        <CallToAction href={link.href} target={link.target} variant="inverted">
          {link?.text}
        </CallToAction>
      )}
    </CardContentWrapper>
  </Card>
)

export default NavigationCallToActionCard
