import { css } from '@emotion/react'
import styled from '@emotion/styled'
import Link from '../components/Link'
import NavigationCard from '../components/NavigationCard'
import NavigationCallToActionCard from '../components/NavigationCallToActionCard'

export const Section = styled('section', {
  shouldForwardProp: (prop) => prop !== 'hasTitle',
})<{ hasTitle: boolean }>(({ theme, hasTitle }) => [
  css`
    --top-background-offset: ${hasTitle ? '210px' : '218px'};
    padding: 0 ${theme.spacing.x2}px;
    background: linear-gradient(
      to bottom,
      transparent var(--top-background-offset),
      ${theme.colors.lightBlue} var(--top-background-offset)
    );

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: 0 ${theme.spacing.x4}px;
      --top-background-offset: ${hasTitle ? '214px' : '112px'};
    }

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      --top-background-offset: ${hasTitle ? '292px' : '166px'};
    }
  `,
])
const Container = styled.div(
  ({ theme }) => css`
    max-width: ${theme.spacing.cardsContainerMaxWidth}px;
    margin: 0 auto;
    padding: ${theme.spacing.x6}px 0;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      padding: ${theme.spacing.x10}px 0;
    }
  `,
)
const Title = styled.h2(
  ({ theme }) => css`
    ${theme.text.heading2(theme)}
    text-align: center;
  `,
)
const CardsContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'layout',
})<{ layout: Layout }>(({ theme, layout }) => [
  css`
    gap: ${theme.spacing.x2}px;
    padding: 0 ${theme.spacing.x2}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: 0 ${theme.spacing.x4}px;
    }
  `,
  layout === 'list' &&
    css`
      display: flex;
      flex-wrap: wrap;
      gap: ${theme.spacing.x4}px;
      padding: 0;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        padding: 0;
      }

      > * {
        width: 100%;
      }

      @media screen and (min-width: ${theme.breakpoints.mobileLarge}px) {
        gap: ${theme.spacing.x6}px;

        > * {
          // We fit 2 cards on a row so we divide the gap value by 2 (since there is 1 gap)
          width: calc(50% - (${theme.spacing.x6}px / 2));
        }
      }

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        gap: ${theme.spacing.x10}px;

        > * {
          // We fit 3 cards on a row so we muliply the gap by 2 (with 3 cards you have 2 gaps per row)
          // Then we divide that by the amount of cards (3)
          width: calc(33.33% - ((${theme.spacing.x10}px * 2) / 3));
        }
      }
    `,
  layout === 'horizontal-scroll' &&
    css`
      display: grid;
      grid-template-columns: 268px;
      grid-auto-flow: column;
      scroll-snap-type: x mandatory;
      scroll-behavior: smooth;
      justify-content: flex-start;
      overflow-x: auto;
      margin: 0 -${theme.spacing.x2}px;
      scroll-padding-left: ${theme.spacing.x2}px;

      > * {
        scroll-snap-align: start;
        width: 268px;
      }

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        scroll-padding-left: ${theme.spacing.x4}px;
        margin: 0 -${theme.spacing.x4}px;
      }

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        gap: ${theme.spacing.x10}px;
        margin: 0;
        padding: 0;
        justify-content: center;
        overflow: visible;
        grid-auto-rows: 1fr;
        grid-template-columns: repeat(3, 1fr);
      }
    `,
])
const CardLink = styled(Link)(
  ({ theme }) => css`
    color: ${theme.colors.bodyTextColor};
    display: block;

    :hover {
      text-decoration: none;
    }
  `,
)

export type Layout = 'list' | 'horizontal-scroll'

type Card = {
  key: string
  image?: ImageInfo
  title: string
  subTitle?: string[] | string | undefined
  text?: string | undefined
  link?: {
    href: string
    target?: string
    text?: string
  }
}

interface Props {
  title?: string
  layout: Layout
  cards?: Card[]
}

const NavigationCardsSection = ({ title, layout, cards }: Props) => (
  <Section hasTitle={!!(title && title.length > 0)}>
    <Container>
      {title && <Title>{title}</Title>}

      <CardsContainer layout={layout}>
        {cards &&
          cards.map((card) => {
            if (card.link) {
              if (!card.image) {
                return <NavigationCallToActionCard {...card} key={card.key} />
              }
              return (
                <CardLink key={card.key} {...card.link}>
                  <NavigationCard {...card} />
                </CardLink>
              )
            }
            return <NavigationCard {...card} key={card.key} />
          })}
      </CardsContainer>
    </Container>
  </Section>
)

export default NavigationCardsSection
