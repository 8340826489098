import { useCallback, useEffect, useId, useRef, useState } from 'react'

// https://developers.google.com/recaptcha/docs/loading#loading_recaptcha_asynchronously
declare global {
  interface Window {
    ___grecaptcha_cfg?: {
      fns: Array<() => void>
    }
  }
}

const onRecaptchaReady = (handler: () => void) => {
  if (window?.___grecaptcha_cfg?.fns === undefined) {
    window.___grecaptcha_cfg = {
      fns: [],
    }
  }

  window.___grecaptcha_cfg.fns.push(handler)
}

const isRecaptchaReady = () =>
  typeof window?.grecaptcha?.execute !== 'undefined'

const getGrecaptcha = () =>
  new Promise<ReCaptchaV2.ReCaptcha>((resolve) => {
    if (isRecaptchaReady()) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      resolve(window.grecaptcha!)
    } else {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      onRecaptchaReady(() => resolve(window.grecaptcha!))
    }
  })

export type UseRecaptchaProps = Record<string, never>

export type UseRecaptchaReturn = {
  isLoading: boolean
  getRecaptchaToken: () => Promise<{ gRecaptchaResponse: string }>
  widgetId?: number
  recaptchaElementId: string
}

const useRecaptcha = () => {
  const [recaptcha, setRecaptcha] = useState<
    ReCaptchaV2.ReCaptcha | undefined
  >()

  useEffect(() => {
    if (isRecaptchaReady()) {
      setRecaptcha(window.grecaptcha)
    } else {
      onRecaptchaReady(() => setRecaptcha(window.grecaptcha))
    }
  }, [])

  return recaptcha
}

export const useRecaptchaWidget = () => {
  const recaptchaElementId = useId()
  const recaptcha = useRecaptcha()

  const recaptchaWidget = useRef<{
    widgetId?: number
    promise?: Promise<{
      gRecaptchaResponse: string
    }>
    resolve?: (value: { gRecaptchaResponse: string }) => void
  }>({})

  const getRecaptchaToken = useCallback(async () => {
    const recaptcha = await getGrecaptcha()
    recaptchaWidget.current.promise = new Promise<{
      gRecaptchaResponse: string
    }>((resolve) => {
      recaptchaWidget.current.resolve = resolve
    })

    await recaptcha.execute(recaptchaWidget.current.widgetId)

    // Wait for Recaptcha Response
    await recaptchaWidget.current.promise

    return {
      gRecaptchaResponse: recaptcha.getResponse(
        recaptchaWidget.current.widgetId,
      ),
    }
  }, [])

  useEffect(() => {
    if (!recaptcha) {
      return
    }
    if (!recaptchaElementId || recaptchaWidget.current.widgetId !== undefined) {
      return
    }

    recaptchaWidget.current.widgetId = recaptcha.render(recaptchaElementId, {
      sitekey: process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITEKEY,
      size: 'invisible',
      badge: 'inline',
      callback: (response) => {
        if (recaptchaWidget.current?.resolve) {
          recaptchaWidget.current?.resolve({ gRecaptchaResponse: response })
        }
      },
    })
  }, [recaptcha, recaptchaElementId])

  return {
    isLoading: recaptcha === undefined,
    getRecaptchaToken,
    widgetId: recaptchaWidget.current?.widgetId,
    recaptchaElementId,
  }
}
