// This is the only place we want to use next/image as is correctly wraps next/image to handle/force the user to use relative cloudinary image urls but still support additional transforms via the loader infrastructure
// eslint-disable-next-line no-restricted-imports
import NextImage, { ImageLoader, ImageProps, StaticImageData } from 'next/image'

import { Theme, useTheme } from '@emotion/react'
import remakeUrl from '../utils/remakeUrl'

const imgixLoader =
  (aspectRatio: string | undefined): ImageLoader =>
  ({ src, width, quality }) => {
    if (src.endsWith('.svg')) {
      return src
    }

    const queryString: Record<string, string | number | undefined> = {
      auto: 'compress,format',
      w: width,
    }
    if (quality) {
      queryString.q = quality
    }
    if (aspectRatio) {
      queryString.fit = 'crop'
      // Note: convertDatoImage sets the focalpoint coordinates
      queryString.crop = 'focalpoint'
      queryString.ar = aspectRatio
    }

    return remakeUrl(src, queryString)
  }

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const rawLoader: ImageLoader = ({ src }) => src

const isStaticImageData = (src: ImageProps['src']): src is StaticImageData =>
  (src as StaticImageData).src !== undefined

const getSrc = (src: ImageProps['src']) => {
  if (typeof src === 'string') {
    return src
  }

  if (isStaticImageData(src)) {
    return src.src
  }

  return src.default.src
}

interface Props extends Omit<ImageProps, 'loader' | 'sizes'> {
  aspectRatio?: string // must be W:H (can be absurd values like 526:426)
  sizes?: (theme: Theme) => string
}

const Image = ({
  aspectRatio,
  sizes,
  width,
  height,
  src,
  ...others
}: Props) => {
  // Static Require images should not use this component
  const realSrc = getSrc(src)
  const isSvg = realSrc.endsWith('.svg')

  const derivedAspectRatio = width && height ? `${width}:${height}` : undefined
  const loader = imgixLoader(aspectRatio || derivedAspectRatio)
  const theme = useTheme()

  // eslint-disable-next-line jsx-a11y/alt-text
  return (
    <NextImage
      {...others}
      src={src}
      width={width}
      height={height}
      sizes={sizes?.(theme)}
      loader={loader}
      unoptimized={isSvg}
    />
  )
}

export default Image
