import { NewsListingSectionFragment } from '../graphql/newsListingSectionFragment.generated'
import { BaseArticleFragment } from '../graphql/articleList.generated'
import NewsListingSection from '../sections/NewsListingSection'
import { usePageContext } from '../utils/PageContext'
import convertDatoImage from '../utils/convertDatoImage'

interface Props {
  content: NewsListingSectionFragment
  data: {
    articles: BaseArticleFragment[]
    categories: string[]
    activeFilter: string
  }
}

const NewsListingSectionBlock = ({
  content: { filterBy, filterAll, sortBy, dateAsc, dateDesc },
  data: { categories, articles, activeFilter },
  ...others
}: Props) => {
  const { siteConfig } = usePageContext()
  if (!siteConfig.podcastsIndex?.slug) {
    return null
  }
  return (
    <NewsListingSection
      /* eslint-disable @typescript-eslint/no-non-null-assertion */
      filterBy={filterBy!}
      filterAll={filterAll!}
      sortBy={sortBy!}
      dateAsc={dateAsc!}
      dateDesc={dateDesc!}
      pathname={`/${siteConfig.newsIndex!.slug}`}
      categories={categories}
      activeFilter={activeFilter}
      articles={articles.map(
        ({ id, title, subTitle, category, image, slug, publishDate }) => {
          return {
            id,
            title: title!,
            subTitle: subTitle || undefined,
            category: category!.title!,
            image: convertDatoImage(image!),
            href: `/${siteConfig.newsIndex!.slug}/${slug}`,
            publishDate: publishDate!,
          }
        },
      )}
      {...others}
    />
  )
}

export default NewsListingSectionBlock
