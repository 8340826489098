import {
  Control,
  FieldErrors,
  UseFormRegister,
  UseFormTrigger,
  useWatch,
} from 'react-hook-form'
import FormFieldText, { IFormInputs, IFormFieldText } from './FormFieldText'

export interface FormFieldTextWithConditionalProps
  extends IFormFieldTextWithConditional {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<IFormInputs & { gRecaptchaResponse: string }, any>
  register: UseFormRegister<IFormInputs>
  errors: FieldErrors<IFormInputs>
  trigger: UseFormTrigger<IFormInputs>
}

export interface IFormFieldTextWithConditional extends IFormFieldText {
  dependentFieldName?: string | undefined
  dependentFieldValue?: string | undefined
}

const FormFieldTextWithConditional = ({
  variation,
  id,
  label,
  name,
  placeholder,
  errorMessage,
  errors,
  pattern,
  required,
  register,
  trigger,
  control,
  dependentFieldName,
  dependentFieldValue,
}: FormFieldTextWithConditionalProps) => {
  const dependentField = useWatch({
    control,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    name: dependentFieldName!,
    defaultValue: '',
  })

  if (dependentField.valueOf() === dependentFieldValue) {
    return (
      <FormFieldText
        id={id}
        label={label}
        variation={variation}
        name={name}
        placeholder={placeholder}
        register={register}
        errors={errors}
        trigger={trigger}
        errorMessage={errorMessage}
        pattern={pattern}
        required={required}
      />
    )
  }

  return null
}

export default FormFieldTextWithConditional
