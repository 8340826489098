import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React from 'react'
import Image from '../components/Image'
import ContinentBlue from './assets/ContinentBlue.svg'

const Container = styled.div(
  ({ theme }) => css`
    max-width: ${theme.spacing.cardsContainerMaxWidth}px;
    margin: 0 auto;
    padding: ${theme.spacing.x6}px ${theme.spacing.x2}px;
    z-index: ${theme.zIndex.relative1};
    position: relative;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: ${theme.spacing.x6}px 0;
    }

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      padding: ${theme.spacing.x20}px 0;
    }
  `,
)

const BackgroundImageWrapper = styled.div(
  ({ theme }) => css`
    width: 100%;
    height: auto;
    position: absolute;
    z-index: ${theme.zIndex.behind};
  `,
)

const StyledBackgroundImage = styled(ContinentBlue)(
  css`
    display: none;
  `,
)

const Title = styled.h2(
  ({ theme }) => css`
    ${theme.text.heading2(theme)}
    color: ${theme.colors.primaryBlue};
    padding: ${theme.spacing.x2}px 0;
    text-align: center;
    margin-bottom: ${theme.spacing.x4}px;

    @media screen and (max-width: ${theme.breakpoints.mobileMax}px) {
      ${theme.text.heading3(theme)}
      color: ${theme.colors.primaryBlue};
      margin-bottom: ${theme.spacing.x4}px;
    }
  `,
)

const Partners = styled('div', {
  shouldForwardProp: (prop) => prop !== 'variant',
})<{ variant: PartnersSectionVariant }>(({ theme, variant }) => [
  variant === 'simple' &&
    css`
      justify-content: space-around;
      align-items: flex-start;
      display: flex;
      flex-wrap: wrap;

      @media screen and (max-width: ${theme.breakpoints.tablet}px) {
        display: flex;
        flex-flow: column;
        align-items: center;
      }
    `,
  variant === 'background' &&
    css`
      /* display: flex;
      gap: ${theme.spacing.x4}px;
      align-items: stretch;
      justify-content: stretch;
      flex-wrap: wrap; */
      display: grid;
      gap: ${theme.spacing.x3}px;
      grid-template-columns: 268px;
      grid-auto-flow: column;
      scroll-snap-type: x mandatory;
      scroll-behavior: smooth;
      overflow-x: auto;
      margin: ${theme.spacing.x3}px ${theme.spacing.x2}px;
      scroll-padding-left: ${theme.spacing.x2}px;
      padding: 0 ${theme.spacing.x2}px;

      > * {
        scroll-snap-align: start;
      }

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        margin: ${theme.spacing.x13}px 0 ${theme.spacing.x3}px;
      }

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        gap: ${theme.spacing.x4}px;
        padding: 0;
        margin-top: ${theme.spacing.x13}px;
        justify-content: space-between;
        overflow: visible;
        grid-auto-rows: 1fr;
        grid-template-columns: repeat(3, 1fr);

        > * {
          width: auto;
        }
      }
    `,
])

const Partner = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-flow: column;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: ${theme.spacing.x6}px;
    width: 250px;
  `,
)

const StyledPartnerImage = styled(Image)(
  css`
    max-width: 120px;
    max-height: 120px;
    border-radius: 50%;
  `,
)

const PartnerInfo = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-flow: column;
    padding: ${theme.spacing.x2}px;
    text-align: center;
  `,
)

const PartnerName = styled.h3(
  ({ theme }) => css`
    ${theme.text.heading3(theme)}
    color: ${theme.colors.primaryBlue};

    @media screen and (max-width: ${theme.breakpoints.desktop}px) {
      font-size: 22px;
    }
  `,
)

const PartnerFunctionTitle = styled.div(
  ({ theme }) => css`
    font-size: 16px;

    @media screen and (max-width: ${theme.breakpoints.desktop}px) {
      font-size: 14px;
    }
  `,
)

export const Section = styled('section', {
  shouldForwardProp: (prop) => prop !== 'variant',
})<{ variant: PartnersSectionVariant }>(({ theme, variant }) => [
  css`
    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: 0 ${theme.spacing.x4}px ${theme.spacing.x14}px;
    }
  `,
  variant === 'background' &&
    css`
      overflow: hidden;
      position: relative;
      min-height: 940px;
      background-image: linear-gradient(
          0deg,
          hsla(0, 0%, 100%, 0) 19.57%,
          ${theme.colors.lightBlue} 0,
          ${theme.colors.lightBlue} 19.63%,
          hsla(0, 0%, 100%, 0) 0,
          hsla(0, 0%, 100%, 0) 41.3%,
          ${theme.colors.lightBlue} 0,
          ${theme.colors.lightBlue} 41.39%,
          hsla(0, 0%, 100%, 0) 0,
          hsla(0, 0%, 100%, 0) 85%,
          ${theme.colors.lightBlue} 0,
          ${theme.colors.lightBlue} 85.09%,
          hsla(0, 0%, 100%, 0) 0
        ),
        linear-gradient(
          90deg,
          hsla(0, 0%, 100%, 0) 99%,
          ${theme.colors.lightBlue}
        );
      background-size: 200px 1104px;
      background-repeat: repeat;
      ${Title} {
        color: black;
      }
      ${StyledBackgroundImage} {
        display: inline-block;
        width: 100%;
        height: auto;
        position: relative;

        @media screen and (min-width: ${theme.breakpoints.desktop}px) {
          top: -225px;
        }
      }
      ${Partner} {
        flex-flow: row;
      }
      ${Partners} {
        padding: 0;
      }
      ${PartnerInfo} {
        text-align: start;
        min-width: 180px;
        padding: ${theme.spacing.x2}px;

        @media screen and (min-width: ${theme.breakpoints.desktop}px) {
          padding: 0;
        }
      }
      ${StyledPartnerImage} {
        padding: ${theme.spacing.x2}px;
      }

      ::after {
        content: '';
        position: absolute;
        width: 116px;
        height: 113px;
        top: 0px;
        border-radius: 50%;
        background-color: ${theme.colors.sand2};
        display: inline-block;
      }

      @media screen and (max-width: ${theme.breakpoints.mobileMax}px) {
        min-height: 450px;
      }

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        ::after {
          content: '';
          position: absolute;
          width: 362px;
          height: 362px;
          border-radius: 50%;
          top: 60px;
          right: 0;
          display: inline-block;
        }
      }
    `,
])

export type PartnersSectionVariant = 'simple' | 'background'

interface PartnerProps {
  image: ImageInfo
  name: string
  functionTitle?: string
}
const PartnerComponent = ({ image, name, functionTitle }: PartnerProps) => (
  <Partner>
    <StyledPartnerImage
      src={image}
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      alt={image.alt!}
      width={120}
      height={120}
    />
    <PartnerInfo>
      <PartnerName>{name}</PartnerName>
      {functionTitle && (
        <PartnerFunctionTitle>{functionTitle}</PartnerFunctionTitle>
      )}
    </PartnerInfo>
  </Partner>
)

interface Props {
  title?: string
  partners: Array<{
    key: string
    image: ImageInfo
    name: string
    functionTitle?: string
  }>
  variant: PartnersSectionVariant
}

const PartnersSection = ({ title, partners, variant }: Props) => (
  <Section variant={variant}>
    <Container>
      <BackgroundImageWrapper>
        <StyledBackgroundImage />
      </BackgroundImageWrapper>
      {title && <Title>{title}</Title>}
      <Partners variant={variant}>
        {partners &&
          partners.map(({ key: id, image, name, functionTitle }) => {
            return (
              <React.Fragment key={id}>
                <PartnerComponent
                  image={image}
                  name={name}
                  functionTitle={functionTitle}
                />
              </React.Fragment>
            )
          })}
      </Partners>
    </Container>
  </Section>
)

export default PartnersSection
