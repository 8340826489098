import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ReactNode } from 'react'
import Image from '../components/Image'

const Container = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-flow: column-reverse;
    position: relative;
    align-items: center;
    width: 100%;

    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      padding: 0;
      flex-flow: row;
      width: ${theme.spacing.gridMaxWidth}px;
    }
  `,
)

export const LeftColumn = styled('div', {
  shouldForwardProp: (prop) => prop !== 'amountOfImages',
})<{ amountOfImages: number }>(({ theme, amountOfImages }) => [
  css`
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: center;
    width: calc(100% - ${theme.spacing.x8}px);
    background-color: ${theme.colors.greenLight};
    padding: ${theme.spacing.x6}px;
    justify-content: flex-end;
  `,
  amountOfImages <= 3
    ? css`
        min-height: 300px;

        @media screen and (min-width: ${theme.breakpoints.desktop}px) {
          min-height: 420px;
        }
      `
    : css`
        min-height: 450px;

        @media screen and (min-width: ${theme.breakpoints.desktop}px) {
          min-height: 700px;
        }
      `,
  css`
    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      min-height: 500px;
      padding: ${theme.spacing.x6}px;
      align-items: flex-start;
      justify-content: center;
      width: 80%;
    }
  `,
])

const Text = styled.div(
  ({ theme }) => css`
    max-width: 360px;
    text-align: center;

    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      text-align: left;
    }
  `,
)

const RightColumn = styled.div(
  ({ theme }) => css`
    position: absolute;
    display: grid;
    grid-template-columns: repeat(3, 187px);
    justify-content: center;
    top: 0;
    z-index: ${theme.zIndex.relative5};
    gap: ${theme.spacing.x1}px;
    width: 100%;
    transform: translateY(-100px);

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      display: grid;
      grid-template-columns: repeat(3, 187px);
    }

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      grid-template-columns: repeat(3, 320px);
    }

    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      position: absolute;
      display: flex;
      top: 50%;
      right: 0;
      flex-flow: row;
      flex-wrap: wrap;
      justify-content: flex-end;
      transform: translateY(-50%);
      width: 50%;
      padding: 0;
      width: 100%;
      max-width: 764px;
    }
  `,
)

export const Title = styled.h2(
  ({ theme }) => css`
    ${theme.text.heading2(theme)};
    color: ${theme.colors.primaryBlue};
    margin: 0 !important;
  `,
)

export const Section = styled.section(({ theme }) => [
  css`
    display: flex;
    flex-flow: row;
    width: 100%;
    justify-content: center;
    padding: ${theme.spacing.x18}px 0 ${theme.spacing.x6}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: ${theme.spacing.x18}px 0 ${theme.spacing.x6}px;
    }

    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      padding: ${theme.spacing.x6}px 0;
    }
  `,
])

const cardWidthSizeMobile = 187
const cardWidthSizeDesktop = 104
const cardWidthSizeDesktopLarge = 320
const cardHeightSizeMobile = 127
const cardHeightSizeDesktopLarge = 215
const CollageImage = styled(Image, {
  shouldForwardProp: (prop) => prop !== 'size',
})<{ size: 'square' | 'small' }>(({ theme, size }) => [
  css`
    --card-size-width: ${cardWidthSizeMobile}px;
    --card-size-height: ${cardHeightSizeMobile}px;
    width: var(--card-size-width);
    height: var(--card-size-height);

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      --card-size-width: ${cardWidthSizeDesktopLarge}px;
      --card-size-height: ${cardHeightSizeDesktopLarge}px;
    }
    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      --card-size-width: ${cardWidthSizeDesktopLarge}px;
      --card-size-height: ${cardHeightSizeDesktopLarge}px;
    }
  `,
  size === 'small' &&
    css`
      @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
        --card-size-width: ${cardWidthSizeDesktop}px;
        --card-size-height: ${cardHeightSizeDesktopLarge}px;
      }
    `,
])

interface Props {
  title: string
  text: ReactNode
  images: ImageInfo[]
}

const TextWithCollageSection = ({ title, text, images }: Props) => {
  const imagesLength = images.length
  return (
    <Section>
      <Container>
        <LeftColumn amountOfImages={imagesLength}>
          <Title>{title}</Title>
          <Text>{text}</Text>
        </LeftColumn>
        <RightColumn>
          {images.map((image, j) => {
            return (
              <CollageImage
                key={image.src}
                src={image}
                alt={image.alt || ''}
                size={
                  (imagesLength === 5 && j === 2) ||
                  (imagesLength === 6 && j === 2) ||
                  (imagesLength === 6 && j === 3) ||
                  (imagesLength === 3 && j === 2)
                    ? 'small'
                    : 'square'
                }
                width={cardWidthSizeDesktopLarge}
                height={cardHeightSizeDesktopLarge}
              />
            )
          })}
        </RightColumn>
      </Container>
    </Section>
  )
}

export default TextWithCollageSection
