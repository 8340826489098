import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

import { useCallback, useEffect, useState } from 'react'

const useDisableBodyScroll = <HTMLElem extends HTMLElement = HTMLDivElement>(
  active = true,
) => {
  const [containerRef, setContainerRef] = useState<HTMLElem | null>(null)

  useEffect(() => {
    if (!containerRef || !active) {
      return
    }

    disableBodyScroll(containerRef, {
      reserveScrollBarGap: true,
    })

    // eslint-disable-next-line consistent-return
    return () => {
      enableBodyScroll(containerRef)
    }
  }, [active, containerRef])

  const setRef = useCallback(
    (elem: HTMLElem | null) => setContainerRef(elem),

    [],
  )

  return setRef
}

export default useDisableBodyScroll
