import Head from 'next/head'
import { jsonLdScriptProps } from 'react-schemaorg'
import { WebSite } from 'schema-dts'
import { useRouter } from 'next/router'

import { SiteConfig } from '../graphql/siteConfig'
import absolutify from '../utils/absolutify'
import { SiteLocale } from '../graphql/datoSchema.generated'

type Props = Pick<SiteConfig, 'title' | 'description'>

const WebsiteRichSnippet = ({ title, description }: Props) => {
  const { locale } = useRouter()
  if (!locale) {
    return null
  }

  return (
    <Head>
      <script
        {...jsonLdScriptProps<WebSite>({
          '@context': 'https://schema.org',
          '@type': 'WebSite',
          '@id': absolutify('/#website', locale as SiteLocale),
          url: absolutify('/', locale as SiteLocale),
          name: title || '',
          description: description || '',
          inLanguage: locale,
          publisher: {
            '@id': absolutify('/#organization', locale as SiteLocale),
          },
          potentialAction: {
            '@type': 'SearchAction',
            target: {
              '@type': 'EntryPoint',
              urlTemplate: absolutify(
                '/search?q={search_term_string}',
                locale as SiteLocale,
              ),
            },
            query: 'required name=search_term_string',
          },
        })}
      />
    </Head>
  )
}

export default WebsiteRichSnippet
