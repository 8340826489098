import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ReactNode } from 'react'
import StarRating from '../components/StarRating'

const Container = styled.div(({ theme }) => [
  css`
    position: relative;
    display: flex;
    flex-flow: column;
    max-width: ${theme.spacing.gridMaxWidth}px;
    margin: 0 auto;
    padding: ${theme.spacing.x8}px 0;
    background: transparent;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      flex-flow: row;
      gap: ${theme.spacing.x6}px;
      padding: ${theme.spacing.x12}px 0;
    }
  `,
])

const Title = styled.h2(({ theme }) => [
  css`
    ${theme.text.heading2(theme)}
    position: relative;
    margin-bottom: ${theme.spacing.x2}px;
    text-align: center;
    margin-bottom: ${theme.spacing.x6}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      text-align: left;
    }

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      margin-bottom: ${theme.spacing.x9}px;
    }
  `,
])

const DataList = styled.dl`
  position: relative;
`

const DataListLabel = styled.dt(({ theme }) => [
  css`
    ${theme.text.heading1(theme)}
    color: ${theme.colors.primaryBlue};
  `,
])

const DataListDescription = styled.dd(({ theme }) => [
  css`
    position: relative;
    color: ${theme.colors.neutral2};
    margin: 0 0 ${theme.spacing.x4}px 0;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      font-size: ${theme.spacing.x3}px;
    }
  `,
])

const Data = styled.div(({ theme }) => [
  css`
    position: relative;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      flex: 50%;
      padding-right: ${theme.spacing.x6}px;
    }
  `,
])

const TestimonialAuthor = styled.span(({ theme }) => [
  css`
    position: relative;
    display: block;
    color: ${theme.colors.primaryBlue};
  `,
])

const TestimonialCompany = styled.span(({ theme }) => [
  css`
    position: relative;
    display: block;
    color: ${theme.colors.neutral2};
  `,
])

const Testimonial = styled.div(({ theme }) => [
  css`
    position: relative;
    padding: ${theme.spacing.x3}px;
    background-color: ${theme.colors.lightBlue};

    p:first-of-type {
      margin-top: 0;
    }

    &::before {
      content: '';
      position: absolute;
      display: inline-block;
      top: -23px;
      left: 0;
      border-style: solid;
      border-width: 0 24px 23px 0;
      border-color: transparent transparent ${theme.colors.lightBlue};
    }
  `,
])

const Testimonials = styled.div(({ theme }) => [
  css`
    position: relative;
    display: flex;
    flex-flow: column;
    gap: ${theme.spacing.x6}px;
    margin-top: ${theme.spacing.x4}px;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      flex-flow: row;
      align-items: center;
      flex: 50%;
      gap: ${theme.spacing.x4}px;
      margin-top: 0;

      &::before {
        content: '';
        position: absolute;
        z-index: 0;
        width: 213px;
        height: 223px;
        background-color: ${theme.colors.sand1};
        top: calc(50% - (223px / 3));
        left: calc(50% + (213px / 4));
        transform: translateY(-50%) translatex(-50%);
      }
    }
  `,
])

const TestimonialsColumnLeft = styled.div(({ theme }) => [
  css`
    position: relative;
    display: flex;
    flex-flow: column;
    gap: ${theme.spacing.x6}px;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      align-items: flex-end;

      ${Testimonial} {
        &:nth-of-type(1) {
          max-width: 263px;

          &::before {
            content: '';
            display: inline-block;
            position: absolute;
            width: 0;
            height: 0;
            bottom: -22px;
            top: auto;
            left: auto;
            right: 0;
            border-color: transparent ${theme.colors.lightBlue} transparent
              transparent;
            border-style: solid;
            border-width: 0 24px 23px 0;
          }
        }

        &:nth-of-type(2) {
          max-width: 362px;

          &::before {
            content: '';
            display: inline-block;
            position: absolute;
            width: 0;
            height: 0;
            top: 0;
            left: -23px;
            border-color: transparent ${theme.colors.lightBlue} transparent
              transparent;
            border-style: solid;
            border-width: 0 24px 23px 0;
          }
        }
      }
    }
  `,
])

const TestimonialsColumnRight = styled.div(({ theme }) => [
  css`
    position: relative;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      ${Testimonial} {
        &:nth-of-type(1) {
          max-width: 263px;

          &::before {
            content: '';
            display: inline-block;
            position: absolute;
            width: 0;
            height: 0;
            top: -22px;
            left: 0;
            border-color: transparent transparent transparent
              ${theme.colors.lightBlue};
            border-style: solid;
            border-width: 23px 0 0 24px;
          }
        }
      }
    }
  `,
])

export const Section = styled('section', {
  shouldForwardProp: (prop) => prop !== 'variant',
})<{ variant: TestimonialsSectionVariant }>(({ theme, variant }) => [
  css`
    position: relative;
    background: ${theme.colors.white};
    padding: 0 ${theme.spacing.x4}px;
  `,
  variant === 'dark' &&
    css`
      background: ${theme.colors.primaryBlue};

      ${Container} {
        &::after {
          content: '';
          position: absolute;
          width: 147px;
          height: 50px;
          background-color: ${theme.colors.error};
          bottom: -50px;
          right: 70px;
        }
      }
      ${Title} {
        color: ${theme.colors.white};
      }
      ${DataListLabel} {
        color: ${theme.colors.white};
      }
      ${DataListDescription} {
        color: ${theme.colors.white};
      }
    `,
])

const TestimonialComponent = ({
  author,
  text,
  company,
}: {
  company?: string
  text: ReactNode
  author: string
}) => (
  <Testimonial>
    {text}
    <TestimonialAuthor>{author}</TestimonialAuthor>
    {company && <TestimonialCompany>{company}</TestimonialCompany>}
  </Testimonial>
)

export type TestimonialsSectionVariant = 'light' | 'dark'

interface Props {
  title: ReactNode
  ratingLabel: string
  rating: number
  positionsFilledLabel: string
  positionsFilled: string
  experience: string
  experienceLabel: string
  testimonials: Array<{
    id: string
    company?: string
    author: string
    text: ReactNode
  }>
  variant: TestimonialsSectionVariant
}

const TestimonialsSection = ({
  title,
  ratingLabel,
  rating,
  positionsFilledLabel,
  positionsFilled,
  experience,
  experienceLabel,
  testimonials,
  variant,
}: Props) => (
  <Section variant={variant}>
    <Container>
      <Data>
        {title && <Title>{title}</Title>}
        <DataList>
          <DataListLabel>{positionsFilled}+</DataListLabel>
          <DataListDescription>{positionsFilledLabel}</DataListDescription>
          <DataListLabel>{experience}+</DataListLabel>
          <DataListDescription>{experienceLabel}</DataListDescription>
          <DataListLabel>
            <StarRating rating={rating} variant={variant} starSize={40} />
          </DataListLabel>
          <DataListDescription>{ratingLabel}</DataListDescription>
        </DataList>
      </Data>
      <Testimonials>
        <TestimonialsColumnLeft>
          {/* We want to seperate the testimonials to correctly align them */}
          {testimonials.map(({ id, company, author, text }, index) => {
            if (index === 0 || index === 2) {
              return (
                <TestimonialComponent
                  key={id}
                  company={company}
                  author={author}
                  text={text}
                />
              )
            }
            return null
          })}
        </TestimonialsColumnLeft>
        <TestimonialsColumnRight>
          {testimonials.map(({ id, company, author, text }, index) => {
            if (index === 1) {
              return (
                <TestimonialComponent
                  key={id}
                  company={company}
                  author={author}
                  text={text}
                />
              )
            }
            return null
          })}
        </TestimonialsColumnRight>
      </Testimonials>
    </Container>
  </Section>
)

export default TestimonialsSection
