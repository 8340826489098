/* eslint-disable camelcase */
import { css, Theme } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps } from 'react'
import { StructuredText } from 'react-datocms/structured-text'

import {
  PageRecord,
  PageTitleRecord,
  QuoteRecord,
  UspListRecord,
  YoutubeVideoRecord,
} from '../graphql/datoSchema.generated'
import { ImageFragment } from '../graphql/imageFragment.generated'
import { LinkWithTextFragment } from '../graphql/linkWithTextFragment'

import {
  LinkWithTextFragment_LinkWithTextExternalRecord_,
  LinkWithTextFragment_LinkWithTextInternalRecord_,
} from '../graphql/linkWithTextFragment.generated'
import convertDatoImage from '../utils/convertDatoImage'
import { usePageContext } from '../utils/PageContext'
import resolveUrlForRecord from '../utils/resolveUrlForRecord'
import ButtonLink from './ButtonLink'
import Image from './Image'
import Link from './Link'
import MissingBlockComponent from './MissingBlockComponent'
import resolveLink from '../utils/resolveLink'
import Quote from './Quote'
import YoutubeVideoPlayer from './YoutubeVideoPlayer'
import PageTitle from './PageTitle'
import { ButtonLinkFragment } from '../graphql/buttonLinkFragment'
import UspList from './UspList'
import { UspListFragment } from '../graphql/uspListFragment.generated'

// Will be a BC API to be used to override certain styles (e.g. remove margin
// from last p)
export const richTextClassName = 'rich-text'

// Adding a "variant" prop to the RichText component would be weird since
// the RichText component is only used in the "blocks" and the blocks don't
// do any styling and are supposed to be unaware of where or how its content is
// used. This css classes gives the section full control instead.
export const darkBackgroundVariantCss = (theme: Theme) => css`
  .${richTextClassName} {
    color: ${theme.colors.neutral0};

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    li::marker {
      color: ${theme.colors.neutral0};
    }
  }
`

const Container = styled.div(({ theme }) => [
  css`
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      &:not(:first-of-type) {
        margin-top: ${theme.spacing.x5}px;
      }

      & + p {
        margin-top: 0;
      }
    }
    h1 {
      ${theme.text.heading1(theme)}
    }
    h2 {
      ${theme.text.heading2(theme)}
    }
    h3 {
      ${theme.text.heading3(theme)}
    }
    h4 {
      ${theme.text.heading4(theme)}
    }
    h5 {
      ${theme.text.heading5(theme)}
    }
    h1,
    h2,
    h3,
    h4,
    h5 {
      margin-bottom: ${theme.spacing.x1}px;
      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        margin-bottom: ${theme.spacing.x2}px;
      }
    }
    ul,
    ol {
      margin: ${theme.spacing.x5}px 0;
      padding-left: ${theme.spacing.x4}px;
      li {
        padding-left: ${theme.spacing.x1}px;

        ::marker {
          color: ${theme.colors.primaryBlue};
        }
      }
      li > p:first-of-type {
        margin-top: 0;
      }
      li > p:last-child {
        margin-bottom: 0;
      }
    }

    img {
      max-width: 100%;
      height: auto;
    }

    > *:last-child {
      margin-bottom: 0;
    }
  `,
])

const StyledButtonLink = styled(ButtonLink)(
  ({ theme }) => css`
    margin: ${theme.spacing.x1}px 0;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      margin: ${theme.spacing.x2}px 0;
    }
  `,
)

type BlockRecords = {
  id: string
  __typename: string
} & (
  | LinkWithTextFragment
  | ButtonLinkFragment
  | {
      image: ImageFragment
    }
  | UspListFragment
)

type LinkRecords = {
  id: string
  __typename: string
} & Pick<PageRecord, '__typename' | 'slug'>

interface Props extends Omit<ComponentProps<typeof Container>, 'children'> {
  text: ComponentProps<typeof StructuredText<BlockRecords, LinkRecords>>['data']
}

const RichText = ({ text, ...others }: Props) => {
  const { siteConfig } = usePageContext()

  return (
    <Container className={richTextClassName} {...others}>
      <StructuredText<BlockRecords, LinkRecords>
        data={text}
        renderBlock={({ record }) => {
          switch (record.__typename) {
            case 'LinkWithTextInternalRecord':
            case 'LinkWithTextExternalRecord': {
              const typedRecord = record as
                | LinkWithTextFragment_LinkWithTextExternalRecord_
                | LinkWithTextFragment_LinkWithTextInternalRecord_

              return (
                <ButtonLink
                  key={record.id}
                  {...resolveLink(siteConfig, typedRecord)}
                  variant="textual"
                >
                  {typedRecord.text}
                </ButtonLink>
              )
            }
            case 'ImageRecord': {
              const typedRecord = record as {
                image: ImageFragment
              }

              return (
                <div>
                  <Image
                    src={convertDatoImage(typedRecord.image)}
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    alt={typedRecord.image.alt!}
                  />
                </div>
              )
            }
            case 'ButtonLinkRecord': {
              const typedRecord = record as ButtonLinkFragment

              return (
                <StyledButtonLink
                  {...resolveLink(siteConfig, typedRecord.link[0])}
                  variant={
                    typedRecord.variant as 'primary' | 'secondary' | 'inverted'
                  }
                >
                  {typedRecord.link[0].text}
                </StyledButtonLink>
              )
            }
            case 'YoutubeVideoRecord': {
              const typedRecord = record as YoutubeVideoRecord

              return (
                (typedRecord.url && (
                  <YoutubeVideoPlayer url={typedRecord.url} />
                )) ||
                null
              )
            }
            case 'QuoteRecord': {
              const typedRecord = record as QuoteRecord

              return (
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                <Quote author={typedRecord.author!}>
                  <RichText
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    text={typedRecord.text!}
                  />
                </Quote>
              )
            }
            case 'PageTitleRecord': {
              const typedRecord = record as PageTitleRecord

              return (
                (typedRecord.title && typedRecord.subTitle && (
                  <PageTitle
                    title={typedRecord.title}
                    subTitle={typedRecord.subTitle}
                  />
                )) ||
                null
              )
            }
            case 'UspListRecord': {
              const typedRecord = record as UspListRecord

              return (
                <UspList>
                  {typedRecord.text && (
                    // Force blocks and links to be empty array because Dato types them as [string] when they are empty, which is incompatible with BlockRecords and LinkRecords
                    <RichText
                      text={{
                        blocks: [],
                        links: [],
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        value: typedRecord.text!.value,
                      }}
                    />
                  )}
                </UspList>
              )
            }
            default:
              return (
                <MissingBlockComponent name={record.__typename as string} />
              )
          }
        }}
        renderLinkToRecord={({ record, children }) => (
          <Link href={resolveUrlForRecord(siteConfig, record)}>{children}</Link>
        )}
        // We can't disable this in DatoCMS but we can't really make it do
        // anything useful either.
        renderInlineRecord={() => null}
      />
    </Container>
  )
}

export default RichText
