import { css } from '@emotion/react'
import styled from '@emotion/styled'
import ChevronRight from '../icons/ChevronRight.svg'
import Link from './Link'
import ButtonLink from './ButtonLink'
import { isMobileMenuOpenVar } from '../sections/NavigationBarSection'
import { useReactiveVar } from '../utils/reactiveVar'
import useDisableBodyScroll from '../utils/useDisableBodyScrollLock'

const Menu = styled('menu', {
  shouldForwardProp: (prop) => prop !== 'isMobileMenuOpen',
})<{ isMobileMenuOpen: boolean }>(({ theme, isMobileMenuOpen }) => [
  css`
    position: fixed;
    display: flex;
    flex-flow: column;
    width: 100%;
    height: calc(100dvh - ${theme.spacing.x10}px);
    list-style: none;
    padding: 0;
    margin: 0;
    top: 79px;
    z-index: ${theme.zIndex.behind2};
    background: ${theme.colors.pageBackgroundColor};
    text-align: left;
    left: 0;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    box-shadow: 0 1px 0 rgba(87, 118, 69, 0.1),
      0 4px 4px rgba(115, 168, 85, 0.13);

    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      display: none;
    }
  `,
  isMobileMenuOpen &&
    css`
      transform: translateX(0);
    `,
])

const HamburgerSubMenu = styled('menu', {
  shouldForwardProp: (prop) =>
    prop !== 'isMobileMenuOpen' &&
    prop !== 'isMobileSubMenuOpen' &&
    prop !== 'visible',
})<{
  isMobileMenuOpen: boolean
  isMobileSubMenuOpen: boolean
  visible: boolean
}>(({ theme, isMobileMenuOpen, isMobileSubMenuOpen, visible }) => [
  css`
    position: fixed;
    display: flex;
    flex-flow: column;
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
    left: 0;
    top: 0;
    height: 100%;
    overflow-y: auto;
    background: ${theme.colors.pageBackgroundColor};
    text-align: left;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    box-shadow: 0 1px 0 rgba(87, 118, 69, 0.1),
      0 4px 4px rgba(115, 168, 85, 0.13);
  `,
  isMobileSubMenuOpen &&
    css`
      transform: ${visible ? 'translateX(0)' : 'translateX(100%)'};
      transition: transform 0.3s ease-in-out;
    `,
  !isMobileMenuOpen &&
    css`
      left: 0;
      transform: translateX(100%);
      transition: transform 0.3s ease-in-out;
    `,
])

const StyledChevronRight = styled(ChevronRight)(css`
  transform: rotate(180deg);
`)

const MenuItem = styled.li(
  ({ theme }) => css`
    display: flex;
    flex-flow: column;
    padding: ${theme.spacing.x2}px;
    border-bottom: 1px solid ${theme.colors.neutral1};
    padding: ${theme.spacing.x3}px;

    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      // Has to be 27 or higher to prevent space between hover and SubMenu
      padding: 27px;
      border-bottom: none;

      :not(:last-of-type) {
        border-right: 1px solid ${theme.colors.neutral1};
      }
    }
  `,
)

const HamburgerCallToActionWrapper = styled.div(
  css`
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
  `,
)
const HamburgerCallToAction = styled(ButtonLink, {
  shouldForwardProp: (prop) => prop !== 'isMobileSubMenuOpen',
})<{ isMobileSubMenuOpen: boolean }>(({ theme, isMobileSubMenuOpen }) => [
  css`
    margin: ${theme.spacing.x6}px ${theme.spacing.x2}px;
    max-width: 343px;
  `,
  isMobileSubMenuOpen &&
    css`
      display: none;
    `,
])

const SubMenuButton = styled.button(
  ({ theme }) => css`
    ${theme.links.blue(theme)};
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
    padding: ${theme.spacing.x3}px;
    width: 100%;
    border: none;
    border-bottom: 1px solid ${theme.colors.neutral1};
    cursor: pointer;
  `,
)

const SubMenuBackButton = styled.button(
  ({ theme }) => css`
    ${theme.links.neutral3(theme)};
    display: flex;
    flex-flow: row;
    align-items: center;
    padding: ${theme.spacing.x3}px;
    border: none;
    border-bottom: 1px solid ${theme.colors.neutral1};
    cursor: pointer;
  `,
)

const MenuLink = styled(Link)(
  ({ theme }) => css`
    ${theme.links.blue(theme)}
    font-family: var(--font-hankenGrotesk);
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
      ${theme.links.neutral3(theme)}
    }

    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      font-size: 20px;
    }
  `,
)

const SubMenuReturnLink = styled(Link)(
  ({ theme }) => css`
    ${theme.links.blue(theme)}
    font-size: 16px;
    padding: ${theme.spacing.x3}px;

    &:hover {
      ${theme.links.neutral3(theme)}
    }
  `,
)

interface Props {
  links: Array<{
    link: {
      key: string
      href: string
      target?: string
      text: string
    }
    subLinks?: Array<{
      link: {
        key: string
        href: string
        target?: string
        text: string
      }
      brief?: string
    }>
  }>
  callToAction: {
    href: string
    target?: string
    text: string
  }
  goTo: string
}

const HamburgerMenu = ({ links, callToAction, goTo }: Props) => {
  const { mainMenuOpen, subMenuOpen, subMenuActiveId } =
    useReactiveVar(isMobileMenuOpenVar)
  const ref = useDisableBodyScroll(mainMenuOpen)
  return (
    <Menu isMobileMenuOpen={mainMenuOpen} ref={ref}>
      {links.map(({ link, subLinks }) => {
        if (subLinks && subLinks?.length > 0) {
          return (
            <div key={`${link.key}HamburgerMenu`}>
              <SubMenuButton
                onClick={() =>
                  isMobileMenuOpenVar({
                    mainMenuOpen: true,
                    subMenuOpen: true,
                    subMenuActiveId: link.key,
                  })
                }
              >
                {link.text}
                <ChevronRight />
              </SubMenuButton>
              <HamburgerSubMenu
                isMobileMenuOpen={mainMenuOpen}
                isMobileSubMenuOpen={subMenuOpen}
                key={`${link.key}HamburgerSubMenu`}
                visible={link.key === subMenuActiveId}
              >
                <SubMenuBackButton
                  onClick={() =>
                    isMobileMenuOpenVar({
                      mainMenuOpen: true,
                      subMenuOpen: false,
                      subMenuActiveId: null,
                    })
                  }
                >
                  <StyledChevronRight />
                  {link.text}
                </SubMenuBackButton>
                {subLinks.map(({ link }) => (
                  <MenuItem key={`${link.key}${link.text}`}>
                    <MenuLink href={link.href} target={link.target}>
                      {link.text}
                    </MenuLink>
                  </MenuItem>
                ))}
                <SubMenuReturnLink href={link.href} target={link.target}>
                  {goTo} {link.text}
                </SubMenuReturnLink>
              </HamburgerSubMenu>
            </div>
          )
        }
        return (
          <MenuItem key={link.key}>
            <MenuLink href={link.href} target={link.target}>
              {link.text}
            </MenuLink>
          </MenuItem>
        )
      })}
      <HamburgerCallToActionWrapper>
        <HamburgerCallToAction
          isMobileSubMenuOpen={subMenuOpen}
          href={callToAction.href}
          target={callToAction.target}
          variant="secondary"
        >
          {callToAction.text}
        </HamburgerCallToAction>
      </HamburgerCallToActionWrapper>
    </Menu>
  )
}

export default HamburgerMenu
