import { ComponentProps } from 'react'
import RichText from '../components/RichText'
import convertDatoImage from '../utils/convertDatoImage'
import { CollageBannerFragment } from '../graphql/collageBannerFragment'
import CollageBanner from '../sections/CollageBanner'

interface Props {
  content: CollageBannerFragment
}

const CollageBannerBlock = ({ content: { columns }, ...others }: Props) => {
  return (
    <CollageBanner
      columns={
        // eslint-disable-next-line array-callback-return, consistent-return
        columns.map(({ size, content }) => {
          // eslint-disable-next-line default-case
          switch (content[0].__typename) {
            case 'CollageImageRecord':
              return {
                // Resize image to prevent large images used, which slows down loading
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                size: size!,
                content: {
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  image: convertDatoImage(content[0].image!),
                  title: content[0].title || undefined,
                  subTitle: content[0].subTitle || undefined,
                },
              }
            case 'CollageTextRecord':
              return {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                size: size!,
                content: {
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  text: <RichText text={content[0].text!} />,
                  variant: content[0].variant || undefined,
                },
              }
          }
        }) as ComponentProps<typeof CollageBanner>['columns']
      }
      {...others}
    />
  )
}

export default CollageBannerBlock
