import { css } from '@emotion/react'
import styled from '@emotion/styled'
import ImageAspectRatio from './ImageAspectRatio'
import Image from './Image'

const Card = styled.article(
  ({ theme }) => css`
    display: flex;
    flex-flow: column;
    background: ${theme.colors.white};
    // Careful changing the size of the box-shadow. When it's bigger than the padding of the container, it will be cut off by the overflow used by the carousel.
    box-shadow: 0px 4px ${theme.spacing.x2}px 0px rgba(38, 107, 238, 0.15);
    width: 100%;
    height: 100%;
    transition: transform 300ms ease-out;

    :hover {
      transform: translateY(-10px);
    }
  `,
)
const CardContent = styled.div(
  ({ theme }) => css`
    padding: ${theme.spacing.x3}px;
  `,
)
const SubTitle = styled.div(
  ({ theme }) => css`
    display: flex;
    color: ${theme.colors.primaryBlue};
    font-weight: 500;
    line-height: 2;
    font-size: 14px;
    margin-bottom: ${theme.spacing.x0}px;
  `,
)
const CardTitle = styled.h3(
  ({ theme }) =>
    css`
      ${theme.text.heading4(theme)}
      margin-bottom: ${theme.spacing.x1}px;
    `,
)
const Text = styled.div``
const SubTitleItem = styled.span(({ theme }) => [
  css`
    position: relative;
    color: ${theme.colors.primaryBlue};

    &:not(:first-of-type) {
      margin-left: ${theme.spacing.x2}px;

      &::before {
        content: '';
        position: absolute;
        width: 4px;
        height: 4px;
        left: -10px;
        top: 50%;
        transform: translateY(-50%);
        background-color: ${theme.colors.neutral2};
        border-radius: 50%;
      }
    }
  `,
])

interface Props {
  image?: ImageInfo
  title: string
  subTitle?: string | Array<string>
  text?: string
}

const NavigationCard = ({ title, subTitle, image, text }: Props) => (
  <Card>
    <ImageAspectRatio aspectRatio={2 / 3}>
      {image && (
        <Image
          src={image}
          alt={title}
          sizes={(theme) =>
            `(min-width: ${theme.breakpoints.desktop}px) 268px, 100vw`
          }
          fill
          aspectRatio="3:2"
        />
      )}
    </ImageAspectRatio>
    <CardContent>
      {subTitle &&
        (subTitle !== '' ||
          (Array.isArray(subTitle) && subTitle?.length > 0)) && (
          <SubTitle>
            {Array.isArray(subTitle) && subTitle?.length > 0
              ? subTitle.map((item, index) => {
                  const key = `${item.replaceAll(
                    ' ',
                    '',
                  )}-${new Date().getTime()}-${index}`
                  return <SubTitleItem key={key}>{item}</SubTitleItem>
                })
              : subTitle}
          </SubTitle>
        )}
      <CardTitle>{title}</CardTitle>
      {text && <Text>{text}</Text>}
    </CardContent>
  </Card>
)

export default NavigationCard
