import { Theme, css } from '@emotion/react'
import styled from '@emotion/styled'
import Image from '../components/Image'
import Email from '../icons/Email.svg'
import Phone from '../icons/Phone.svg'
import Link from '../components/Link'

const Section = styled.section(
  ({ theme }) => css`
    position: relative;
    padding: ${theme.spacing.x4}px ${theme.spacing.x2}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: ${theme.spacing.x10}px ${theme.spacing.x4}px;
    }
  `,
)

const Container = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-flow: column;
    max-width: ${theme.spacing.cardsContainerMaxWidth}px;
    margin: 0 auto;
  `,
)

const Title = styled.h2(
  ({ theme }) => css`
    ${theme.text.heading2(theme)}
    color: ${theme.colors.primaryBlue};
    align-self: center;
  `,
)

const Employees = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-flow: column;
    gap: ${theme.spacing.x4}px;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      gap: ${theme.spacing.x8}px;
    }
  `,
)

const Employee = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-flow: column;
    align-items: center;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      flex-flow: row;
    }
  `,
)

const StyledEmployeeImage = styled(Image)(
  ({ theme }) => css`
    max-width: 120px;
    max-height: 120px;
    border-radius: 50%;
    margin: ${theme.spacing.x2}px;
    border: 3px solid ${theme.colors.primaryBlue};
  `,
)

const EmployeeWrapper = styled.div(
  ({ theme }) =>
    css`
      display: flex;
      flex-flow: column;

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        margin-left: ${theme.spacing.x2}px;
      }
    `,
)

const EmployeeNameWrapper = styled.span(
  ({ theme }) => css`
    display: flex;
    flex-flow: column;
    padding: ${theme.spacing.x1}px 0;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      flex-flow: row;
      align-items: center;
    }
  `,
)

const EmployeeName = styled.h3(
  ({ theme }) => css`
    ${theme.text.heading3(theme)}
  `,
)

const reusableStyling = (theme: Theme) => css`
  ${theme.text.heading4(theme)}
  color: ${theme.colors.primaryBlue};
`

const Spacer = styled.span(
  ({ theme }) => css`
    display: none;
    ${reusableStyling(theme)}
    margin-left: ${theme.spacing.x1}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      display: block;
    }
  `,
)

const EmployeeFunction = styled.h4(
  ({ theme }) => css`
    ${reusableStyling(theme)}

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      margin-left: ${theme.spacing.x1}px;
    }
  `,
)

const EmployeeBio = styled.div(
  ({ theme }) => css`
    color: ${theme.colors.bodyTextColor};
  `,
)

const ContactDetails = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-flow: column;
    color: ${theme.colors.bodyTextColor};
    padding-top: ${theme.spacing.x2}px;
    gap: ${theme.spacing.x1}px;
    width: fit-content;
  `,
)

const ContactInfo = styled(Link)(
  ({ theme }) => css`
    ${theme.links.neutral3(theme)}
    display: flex;
    flex-flow: row;
    gap: ${theme.spacing.x2}px;
  `,
)

const IconWrapper = styled.div(
  ({ theme }) => css`
    width: ${theme.spacing.x3}px;
  `,
)

interface Props {
  title: string
  employees: Array<{
    image: ImageInfo
    name: string
    functionTitle: string
    bio?: string
    email?: {
      href: string
      target?: string
      text?: string
    }
    phone?: {
      href: string
      target?: string
      text?: string
    }
  }>
}

const EmployeeSection = ({ title, employees }: Props) => (
  <Section>
    <Container>
      <Title>{title}</Title>
      <Employees>
        {employees &&
          employees.map(({ image, name, functionTitle, bio, email, phone }) => {
            return (
              <Employee key={name}>
                <StyledEmployeeImage
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  src={image!}
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  alt={image.alt!}
                  width={120}
                  height={120}
                />
                <EmployeeWrapper>
                  <EmployeeNameWrapper>
                    <EmployeeName>{name}</EmployeeName>
                    <Spacer> - </Spacer>
                    <EmployeeFunction>{functionTitle}</EmployeeFunction>
                  </EmployeeNameWrapper>
                  {bio && <EmployeeBio>{bio}</EmployeeBio>}
                  <ContactDetails>
                    {email && (
                      <ContactInfo {...email}>
                        <IconWrapper>
                          <Email />
                        </IconWrapper>
                        {email.text}
                      </ContactInfo>
                    )}
                    {phone && (
                      <ContactInfo {...phone}>
                        <IconWrapper>
                          <Phone />
                        </IconWrapper>
                        {phone.text}
                      </ContactInfo>
                    )}
                  </ContactDetails>
                </EmployeeWrapper>
              </Employee>
            )
          })}
      </Employees>
    </Container>
  </Section>
)

export default EmployeeSection
