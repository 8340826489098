import RichText from '../components/RichText'
import { FeaturesSectionFragment } from '../graphql/featuresSectionFragment'
import FeaturesSection from '../sections/FeaturesSection'
import convertDatoImage from '../utils/convertDatoImage'

interface Props {
  content: FeaturesSectionFragment
}

const FeaturesSectionBlock = ({
  content: { text, features },
  ...others
}: Props) => {
  return (
    <FeaturesSection
      text={<RichText text={text} />}
      features={features.map(({ id, image, title, text }) => {
        // eslint-disable-next-line default-case
        return {
          id,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          image: convertDatoImage(image!),
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          title: title!,
          text: <RichText text={text} />,
        }
      })}
      {...others}
    />
  )
}

export default FeaturesSectionBlock
