import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { secondaryButtonCss } from '../components/Button'
import ButtonLink from '../components/ButtonLink'

const Section = styled.section(({ theme }) => [
  css`
    display: flex;
    width: 100%;
    justify-content: center;
    padding: ${theme.spacing.x5}px ${theme.spacing.x2}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: ${theme.spacing.x5}px ${theme.spacing.x4}px;
    }
  `,
])

const Container = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-flow: column;
    align-items: center;
    text-align: center;
    margin: 0 auto;
    max-width: ${theme.spacing.containerWidth};
    width: 100%;
  `,
)

export const Title = styled.h1(
  ({ theme }) => css`
    ${theme.text.heading1(theme)};
    color: ${theme.colors.primaryBlue};
    margin: 0 !important;
  `,
)

const CallToAction = styled(ButtonLink)(({ theme }) => [
  secondaryButtonCss(theme),
  css`
    display: flex;
    align-self: center;
    margin-top: ${theme.spacing.x4}px;
  `,
])

interface Props {
  title: string
  callToAction: {
    href: string
    target?: string
    text: string
  }
}

const SimpleHeaderSection = ({ title, callToAction }: Props) => {
  return (
    <Section>
      <Container>
        <Title>{title}</Title>
        {callToAction && (
          <CallToAction
            href={callToAction.href}
            target={callToAction.target}
            variant="primary"
          >
            {callToAction.text}
          </CallToAction>
        )}
      </Container>
    </Section>
  )
}

export default SimpleHeaderSection
