import { PodcastListingSectionFragment } from '../graphql/PodcastListingSectionFragment.generated'
import { BasePodcastFragment } from '../graphql/podcastList.generated'
import PodcastListingSection from '../sections/PodcastListingSection'
import { usePageContext } from '../utils/PageContext'
import convertDatoImage from '../utils/convertDatoImage'

interface Props {
  content: PodcastListingSectionFragment
  data: {
    podcasts: BasePodcastFragment[]
    categories: string[]
  }
}

const PodcastListingSectionBlock = ({
  content: { filterBy, filterAll },
  data,
  ...others
}: Props) => {
  const { siteConfig } = usePageContext()
  if (!siteConfig.podcastsIndex?.slug) {
    return null
  }
  return (
    <PodcastListingSection
      /* eslint-disable @typescript-eslint/no-non-null-assertion */
      filterBy={filterBy!}
      filterAll={filterAll!}
      pathname={`/${siteConfig.podcastsIndex.slug}`}
      categories={data.categories}
      podcasts={data.podcasts.map((podcast) => {
        return {
          id: podcast.id,
          title: podcast.title!,
          subTitle: podcast.subTitle || undefined,
          category: podcast.category!.title!,
          image: convertDatoImage(podcast.image!),
          soundcloudLink: podcast.soundcloudLink!,
          publishedDate: podcast.publishedDate!,
        }
      })}
      {...others}
    />
  )
}

export default PodcastListingSectionBlock
