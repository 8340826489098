import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps } from 'react'

import {
  primaryButtonCss,
  invertedButtonCss,
  secondaryButtonCss,
} from './Button'
import ArrowRightSvg from '../icons/ArrowRight.svg'
import Link from './Link'

export const StyledLink = styled(Link, {
  shouldForwardProp: (prop) => prop !== 'variant',
})<{ variant: 'textual' | 'primary' | 'secondary' | 'inverted' }>(
  ({ theme, variant }) => [
    css`
      display: inline-block;
      font-weight: bold;
      color: inherit;
    `,
    variant === 'primary' && primaryButtonCss(theme),
    variant === 'secondary' && secondaryButtonCss(theme),
    variant === 'inverted' && invertedButtonCss(theme),
    css`
      display: inline-flex;
      flex-flow: row;
      gap: ${theme.spacing.x2}px;
    `,
  ],
)
const Text = styled.div`
  flex: 1 1 auto;
`
const ArrowRight = styled(ArrowRightSvg)(
  ({ theme }) => css`
    margin-left: ${theme.spacing.x1}px;
    height: 1em;
    color: inherit;
    align-self: center;
  `,
)

interface Props extends ComponentProps<typeof Link> {
  variant: 'textual' | 'primary' | 'secondary' | 'inverted'
}

const ButtonLink = ({ children, variant, ...others }: Props) => (
  <StyledLink variant={variant} {...others}>
    <Text>{children}</Text>
    {variant !== 'textual' && <ArrowRight />}
  </StyledLink>
)

export default ButtonLink
