import xml2js from 'xml2js'
import { makeUnique } from './makeUnique'

const parser = new xml2js.Parser()

export type JobsResponse = {
  items: Job[]
  facets?: {
    functionArea: string[]
  }
}

export type Job = {
  id: string
  // Removed because not used
  // subID?: string
  functionName: string
  jobType?: string
  positions?: number
  functionArea: string
  sector?: string
  salary?: string
  salaryGeneral?: string
  region?: string
  location?: string
  date: string
  companyLogo?: string
  image?: string
  link?: string
}

export type CUSTOMFIELD = {
  $: {
    TITLE: string
  }
  CUSTOMFIELDID: string
  CUSTOMFIELDVALUE: string
}

type SortOption = 'Date_Asc' | 'Date_Desc'

const getVacanciesOverview = async (
  limit?: number,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sort?: SortOption,
): Promise<JobsResponse> => {
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_VACANCY_OVERVIEW_URL}`,
  )

  if (!response.ok) {
    // eslint-disable-next-line no-console
    console.error(
      `Something went wrong ${response.status}: ${response.statusText}`,
    )
    const items: [] = []
    return { items }
  }

  const xml = await response.text()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const parsedData: { JOBLIST: { JOB: Record<string, any>[] } } =
    await parser.parseStringPromise(xml)

  const items = parsedData.JOBLIST.JOB.map((jobData) => {
    const job: Job = {
      id: jobData.$.ID,
      // subID: jobData.$.SUBID,
      functionName: jobData.FUNCTION[0],
      jobType: jobData.JOBTYPE[0],
      positions: Number(jobData.POSITIONS[0]).valueOf(),
      functionArea: jobData.FUNCTIONAREA[0],
      sector: jobData.SECTOR[0],
      salary: jobData.SALARY[0],
      salaryGeneral: jobData.SALARYGENERAL[0],
      region: jobData.REGION[0],
      location: jobData.LOCATION[0],
      date: jobData.DATEMODIFY[0],
      companyLogo: jobData.COMPANYLOGO[0].replace('//images', '/images'),
      image: jobData.CUSTOMFIELDLIST.map(
        (fieldList: Record<string, CUSTOMFIELD[]>) =>
          fieldList.CUSTOMFIELD.filter(
            (field: CUSTOMFIELD) => field.$.TITLE === 'Afbeelding',
          ).map((field: CUSTOMFIELD) => field.CUSTOMFIELDVALUE[0])[0],
      )[0],
    }
    return job
  })

  // Sort edits the array
  if (sort !== undefined) {
    items.sort((a, b) =>
      sort === 'Date_Desc'
        ? -1
        : // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          1 * (new Date(a.date!).getTime() - new Date(b.date!).getTime()),
    )
  }

  const pagedItems = limit !== undefined ? items.splice(0, limit) : items

  const facets = {
    functionArea: makeUnique(
      items
        .filter((vacancy) => vacancy.functionArea !== '')
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .map((vacancy) => vacancy.functionArea!),
    ),
  }

  return { items: pagedItems, facets }
}

export default getVacanciesOverview
